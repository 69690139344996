<mat-card class="innerContent" [style.display]="!isSpinnerVisible ? 'none' : 'block'">
    <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
</mat-card>
<mat-card class="innerContent" *ngIf="!isSpinnerVisible && dataSource?.data?.length === 0">
    <div style="text-align: center">{{ 'devices.data.nothing' | translate }}</div>
</mat-card>

<mat-card
    [style.display]="isSpinnerVisible ? 'none' : 'block'"
    *ngIf="showChart && dataSource?.data?.length != 0 && tab === 'instant_readings' && isVectorChart"
    class="innerContent device-chart-content"
>
    <mat-card-title>
        <span style="vertical-align: middle">{{ 'devices.data_show.chart' | translate }}</span>
        <mat-slide-toggle [(ngModel)]="isVectorChart" (change)="toggleVectorChartHandler($event)" class="vector-chart--toggle">{{
            'devices.data_show.vector_chart' | translate
        }}</mat-slide-toggle>
    </mat-card-title>
    <div id="deviceVectorChart" style="overflow: hidden"></div>
    <div id="deviceVectorChartSum" style="overflow: hidden"></div>
    <div class="vector-chart--info" *ngIf="selectedMessage?.datetime === dataSource.data[dataSource.data?.length - 1]?.datetime">
        {{ 'devices.data_show.big_text' | translate }}
    </div>
</mat-card>

<mat-card
    [style.display]="isSpinnerVisible ? 'none' : 'block'"
    *ngIf="showChart && dataSource?.data?.length != 0 && !isVectorChart"
    class="innerContent device-chart-content"
>
    <mat-card-title>
        <span style="vertical-align: middle">{{ 'devices.data_show.chart' | translate }}</span>
        <mat-slide-toggle
            *ngIf="tab === 'instant_readings'"
            [(ngModel)]="isVectorChart"
            (change)="toggleVectorChartHandler($event)"
            class="vector-chart--toggle"
            >{{ 'devices.data_show.vector_chart' | translate }}</mat-slide-toggle
        >
    </mat-card-title>
    <mat-card-subtitle *ngIf="standard"> {{ 'devices.data_show.standard' | translate }}: {{ standard }} {{ unit }} </mat-card-subtitle>
    <div id="deviceChart" style="overflow: hidden" class="w-100"></div>
</mat-card>

<mat-card [style.visibility]="isSpinnerVisible == true || dataSource.data.length == 0 ? 'hidden' : 'visible'" class="innerContent">
    <mat-card-title>
        <span style="vertical-align: middle">{{ 'devices.data_show.data' | translate }}</span>
        <div class="fields-setting-menu">
            <ng-container *ngIf="(tab === 'tariffs' || tab === 'archives')">
                <mat-checkbox
                    *ngIf="isDeltaFieldsAvailable()"
                    class="table-params-toggle"
                    [checked]="withDelta"
                    (change)="toggleDeltaKktLoss($event, 'delta')"
                    >{{ 'devices.data_show.with_delta' | translate }}
                </mat-checkbox>
                <mat-checkbox
                    *ngIf="isLossFieldsAvailable()"
                    class="table-params-toggle"
                    [checked]="withLoss"
                    (change)="toggleDeltaKktLoss($event, 'loss')"
                    >{{ 'devices.data_show.with_losses' | translate }}
                </mat-checkbox>
                <mat-checkbox
                    *ngIf="isKktFieldsAvailable()"
                    class="table-params-toggle"
                    [checked]="withKt"
                    (change)="toggleDeltaKktLoss($event, 'kkt')"
                    >{{ 'devices.data_show.with_ct' | translate }}
                </mat-checkbox>
            </ng-container>
            <button
                mat-icon-button
                matTooltip="{{ 'devices.data_show.msg_1' | translate }}"
                matTooltipPosition="above"
                (click)="refreshDataHandler()"
            >
                <mat-icon>refresh</mat-icon>
            </button>
            <button
                mat-icon-button
                matTooltip="{{ 'devices.data_show.msg_2' | translate }}"
                matTooltipPosition="above"
                (click)="downloadExcel()"
            >
                <mat-icon class="personal_design_icon">cloud_download</mat-icon>
            </button>
            <button
                mat-icon-button
                matTooltip="{{ 'devices.data_show.msg_3' | translate }}"
                matTooltipPosition="above"
                [matMenuTriggerFor]="menu"
            >
                <mat-icon>settings</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <mat-list>
                    <mat-list-item *ngFor="let field of settingsColumns" [style.display]="field === 'datetime' ? 'none' : 'block'">
                        <mat-checkbox
                            [checked]="getCheckedStatus(field)"
                            (click)="$event.stopPropagation()"
                            (change)="changeFieldVisible(field)"
                        >
                            {{getMessageTitleName(field)}}
                        </mat-checkbox>
                    </mat-list-item>
                </mat-list>
            </mat-menu>
        </div>
    </mat-card-title>
    <div class="data-table">
        <table
            mat-table
            matSort
            [dataSource]="dataSource"
            class="mat-elevation-z8 device-data"
            matSortActive="datetime"
            matSortDisableClear
            matSortDirection="desc"
        >
            <ng-container matColumnDef="datetime" sticky>
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ 'app_accounting_point.control.msg_12' | translate }}
                </th>
                <td
                    mat-cell
                    *matCellDef="let message"
                    [class]="'mat-cell cdk-cell ng-star-inserted'"
                    [class.active]="message.datetime === selectedMessage?.datetime"
                    (click)="setSelectedMessage(message)"
                >
                    <p>
                        {{ getFormattedDate(message['datetime']) }}
                    </p>
                </td>
            </ng-container>
            <ng-container *ngFor="let field of displayedColumns; let i = index">
                <ng-container *ngIf="field !== 'datetime' && field !== 'message_api'" matColumnDef="{{ field }}">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        <ng-container *ngIf="field !== 'delete_message'">
                            {{getMessageTitleName(field)}}
                        </ng-container>
                    </th>
                    <td
                        mat-cell
                        *matCellDef="let message"
                        [class]="'mat-cell cdk-cell ng-star-inserted'"
                        [class.active]="message.datetime === selectedMessage?.datetime"
                        (click)="setSelectedMessage(message)"
                    >
                        <ng-container *ngIf="field !== 'delete_message' || tab === 'journal'; else emptyTd">
                            <p *ngIf="field === 'datetime' || field === 'created_at' || field === 'realdatetime'">
                                {{ getFormattedDate(message[field]) }}
                            </p>
                            <p
                                *ngIf="
                                    field !== 'datetime' &&
                                    field !== 'created_at' &&
                                    field !== 'realdatetime' &&
                                    typeof(message[field]) === 'number'
                                "
                            >
                                {{ message[field] | number: '1.0-4' }}
                            </p>
                            <p
                                *ngIf="
                                    field !== 'datetime' &&
                                    field !== 'created_at' &&
                                    field !== 'realdatetime' &&
                                    field !== 'raw_file_field' &&
                                    typeof(message[field]) !== 'number'
                                "
                            >
                                <ng-container *ngIf="field !== 'event_name'; else eventField">
                                    {{ message[field] }}
                                </ng-container>
                                <ng-template #eventField>
                                    {{ lang !== 'ru' && message['event_name_en'] ? message['event_name_en'] : message['event_name'] }}
                                </ng-template>
                            </p>
                            <p
                                *ngIf="
                                    field !== 'datetime' &&
                                    field !== 'created_at' &&
                                    field !== 'realdatetime' &&
                                    field === 'raw_file_field' &&
                                    typeof(message[field]) !== 'number'
                                "
                            >
                                <button mat-button (click)="downloadRawFile(message['datetime'], message[field])">
                                    <mat-icon class="download personal_design_icon"> cloud_download </mat-icon>
                                </button>
                            </p>
                        </ng-container>
                        <ng-template #emptyTd>
                            <div
                                class="delete-message"
                                matTooltip="{{ 'devices.data_show.msg_4' | translate }}"
                                matTooltipPosition="above"
                                (click)="onDeleteMessage(message)"
                            >
                                <span class="material-icons"> cancel </span>
                            </div>
                        </ng-template>
                    </td>
                </ng-container>
                <ng-container *ngIf="field === 'message_api'" matColumnDef="message_api">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td
                        mat-cell
                        *matCellDef="let message"
                    >
                        <mat-icon
                            *ngIf="message.message_api"
                            style="font-size: 24px; vertical-align: middle; cursor: help"
                            [matTooltip]="getMessageApiInfo(message)"
                            matTooltipClass="my-tooltip"
                        >info</mat-icon>
                    </td>
                </ng-container>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
    </div>

    <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
</mat-card>
