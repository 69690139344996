import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {CloudComponent} from 'src/app/cloud/cloud.component';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {combineLatest, Observable, ReplaySubject} from 'rxjs';
import {DevicesService} from '../../../../services/devices.service';
import * as moment from 'moment';
import 'moment-timezone';
import * as echarts from 'echarts';
import {CheckAccess, CloudService, DateManipulations} from 'src/app/cloud/cloud.service';
import {MeteringDevicesDataComponent} from '../data.component';
import 'zrender/lib/svg/svg';
import * as _ from 'lodash';
import {takeUntil} from 'rxjs/operators';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {MatCheckboxChange} from '@angular/material/checkbox';
import * as Excel from 'exceljs/dist/exceljs.min.js';
import * as fs from 'file-saver';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmPopupComponent} from '../../../../components/confirm-popup/confirm-popup.component';
import {TranslateLanguageService} from '@core/services/translate-language.service';
import {select, Store} from '@ngrx/store';
import {selectDeviceFieldTitlesState} from 'src/app/store/selectors/selectors';
import {DataGatewaysComponent} from 'src/app/cloud/devices/pages/data/gateways/data.component';

const SETTING_DEVICE = [
    'datetime',
    'in1',
    'start_in1',
    'end_in1',
    'delta_in1',
    'in2',
    'start_in2',
    'end_in2',
    'delta_in2',
    'in3',
    'start_in3',
    'end_in3',
    'delta_in3',
    'in4',
    'start_in4',
    'end_in4',
    'delta_in4',
    'tariff1',
    'start_tariff1',
    'end_tariff1',
    'delta_tariff1',
    'tariff2',
    'start_tariff2',
    'end_tariff2',
    'delta_tariff2',
    'tariff3',
    'start_tariff3',
    'end_tariff3',
    'delta_tariff3',
    'tariff4',
    'start_tariff4',
    'end_tariff4',
    'delta_tariff4',
    'tariff5',
    'start_tariff5',
    'end_tariff5',
    'delta_tariff5'
];

@Component({
    selector: 'metering-devices-data-show',
    templateUrl: './data-show.component.html',
    styleUrls: ['./data-show.component.less'],
})
export class MeteringDevicesDataShowComponent implements OnInit, OnDestroy {
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    @Input() device: any;
    @Input() dateForm: any;
    @Input() msgGroup: any;
    @Input() tab: string;
    @Input() msgType?: number;
    @Input() accountingPoint: any;
    @Input() deviceType?: 'device' | 'gateway';

    public app;
    public selectedMessage;
    public showChart = true;
    public chart;
    public isSpinnerVisible = true;
    public dataSource = new MatTableDataSource();
    public displayedColumns: any;
    public availableColumns: string[] = [];
    public settingsColumns: string[] = [];
    public fieldsTitles: any[] = [];
    public settings;
    public isVectorChart = false;
    public currentMessages;
    public lang: string;

    private readonly modifiedColumns: string[] = [
        'in1',
        'tariff1',
        'tariff2',
        'tariff3',
        'tariff4',
        'tariff5',
        'tariff_negative1',
        'tariff_negative2',
        'tariff_negative3',
        'tariff_negative4',
        'tariff_negative5',
        'tariff_reactive1',
        'tariff_reactive2',
        'tariff_reactive3',
        'tariff_reactive4',
        'tariff_reactive5',
        'tariff_reactive1_negative',
        'tariff_reactive2_negative',
        'tariff_reactive3_negative',
        'tariff_reactive4_negative',
        'tariff_reactive5_negative',
    ];
    private readonly forbiddenColumns: string[] = ['event_name_en', 'reason', 'method', 'message_api', 'delete_message'];

    set setDisplayedColumns(value) {
        const columns = value.filter((el) => !this.forbiddenColumns.includes(el));
        this.displayedColumns = _.uniq(['datetime', ...columns]);
        this.displayedColumns = this.displayedColumns.filter(
            (el) => el !== 'event_name_en' && el !== 'reason' && el !== 'method'
        );
        this.displayedColumns.push('message_api');
        this.displayedColumns.push('delete_message');
        this.displayedColumns = this.displayedColumns.map((c) => this.addPrefixesToColumn(c));
    }

    private readonly destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
    private mapDeviceGroup = {
        1: 1,
        2: 1,
        3: 4,
        4: 3,
        5: 2
    };
    withKt = false;
    withLoss = false;
    withDelta = false;

    get unit() {
        switch (this.device?.tied_point?.resource_type_id) {
            case 1:
                return this.translateLanguageService.translateInstant('app_accounting_point.standard.square_meter');
            case 2:
                return this.translateLanguageService.translateInstant('app_accounting_point.standard.square_meter');
            case 3:
                return this.translateLanguageService.translateInstant('app_accounting_point.standard.kvtch');
            case 4:
                return this.translateLanguageService.translateInstant('app_accounting_point.standard.gcal');
            case 5:
                return this.translateLanguageService.translateInstant('app_accounting_point.standard.square_meter');
            case 6:
                return '';
            case 7:
                return this.translateLanguageService.translateInstant('app_accounting_point.standard.gcal');
            case 8:
                return this.translateLanguageService.translateInstant('app_accounting_point.standard.square_meter');
            case 9:
                return this.translateLanguageService.translateInstant('app_accounting_point.standard.square_meter');
            default:
                return '';
        }
    }

    get standard() {
        if (!this.device?.tied_point?.standard) {
            return null;
        }
        if (this.tab === 'tariffs') {
            switch (this.msgGroup) {
                case 1:
                    return this?.device?.tied_point?.standard?.day;
                case 3:
                    return this?.device?.tied_point?.standard?.month;
                case 4:
                    return this?.device?.tied_point?.standard?.hour;
                default:
                    return null;
            }
        }
        if (this.tab === 'archives') {
            switch (this.msgType) {
                case 101:
                    return this?.device?.tied_point?.standard?.day;
                case 103:
                    return this?.device?.tied_point?.standard?.month;
                case 100:
                    return this?.device?.tied_point?.standard?.hour;
                default:
                    return null;
            }
        }
    }

    constructor(
        private store: Store<{}>,
        private translate: TranslateService,
        public cloud: CloudComponent,
        private devicesService: DevicesService,
        private _cloudService: CloudService,
        public _checkAccess: CheckAccess,
        public dialog: MatDialog,
        private translateLanguageService: TranslateLanguageService,
        public dateManipulations: DateManipulations
    ) {
        CloudService.setLanguage(translate);
    }

    ngOnInit() {
        combineLatest([
            this.translateLanguageService.currentLanguage$,
            this.store.pipe(select(selectDeviceFieldTitlesState)),
            this._cloudService.settings,
            this.devicesService.refreshData
        ])
            .pipe(takeUntil(this.destroyed$))
            .subscribe(([lang, fieldTitlesRes, settings, data]) => {
                this.lang = lang;
                fieldTitlesRes.filter((el: any) => el.id === this.device.device_fields_titles.find((elem) => elem.id === el.id)?.id).forEach((field: any) => this.fieldsTitles[field.name] = field);
                this.settings = settings;
                if (data) {
                    this.refreshData(data);
                }
            });
    }

    refreshData(data) {
        if (this.device) {
            this.dataSource = new MatTableDataSource();
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
            this.msgType = data?.['msgType'];
            this.getMessages(data?.['msgType'], data?.['tab'])
                .pipe(takeUntil(this.destroyed$))
                .subscribe(() => {
                    setTimeout(() => {
                        this.isSpinnerVisible = false;
                    }, 500);
                });
        } else if (this.accountingPoint?.tabs) {
            this.withKt = true;
            this.withLoss = true;
            this.withDelta = true;
            this.devicesService.getDeviceFieldTitles().subscribe((result: any) => {
                const filteredFields = result.data.fields.filter(
                    (field) =>
                        (field.device_group_id === this.mapDeviceGroup[this.accountingPoint.currentPoint?.resource_type_id] ||
                            field.device_group_id === null) &&
                        field.protocol_id === null &&
                        field.device_type_id === null &&
                        field.device_id === null
                );
                filteredFields.forEach((field) => {
                    this.fieldsTitles[field.name] = field;
                });
                this.dataSource = new MatTableDataSource();
                this.dataSource.sort = this.sort;
                this.dataSource.paginator = this.paginator;
                this.getMessages(data['msgType'], data['tab'], 'accounting_point')
                    .pipe(takeUntil(this.destroyed$))
                    .subscribe(() => {
                        setTimeout(() => {
                            this.isSpinnerVisible = false;
                        }, 500);
                    });
            });
        }
    }

    getMessageTitleName(title) {
        const field = this.cleanMessageTitleFromPrefixes(title);
        const fieldName = this.fieldsTitles[field]
            ? this.fieldsTitles[field]?.['title']
            : field;
        const fieldUnit = this.fieldsTitles[field]?.unit
            ? ', ' + this.fieldsTitles[field]?.unit?.['title']
            : '';
        return `${fieldName} ${fieldUnit}`;
    }

    cleanMessageTitleFromPrefixes(title) {
        return title.replace(/delta_/i, '').replace(/_kkt/i, '').replace(/_loss_factor/i, '');
    }

    addPrefixesToColumn(dirtycolumn) {
        const column = this.cleanMessageTitleFromPrefixes(dirtycolumn);
        const isWithDelta = this.modifiedColumns.includes(column);
        if (!isWithDelta) {
            return dirtycolumn;
        }
        return `${this.withDelta ? 'delta_' : ''}${column}${this.withLoss ? '_loss_factor' : ''}${this.withKt ? '_kkt' : ''}`;
    }

    isColumnWithAllPrefixes(column) {
        return this.isColumnWithKkt(column) && this.isColumnWithLoss(column) && this.isColumnWithDelta(column);
    }

    isColumnWithKkt(column) {
        return this.withKt ? column.search(/_kkt/i) !== -1 : column.search(/_kkt/i) === -1;
    }

    isColumnWithLoss(column) {
        return this.withLoss ? column.search(/_loss_factor/i) !== -1 : column.search(/_loss_factor/i) === -1;
    }

    isColumnWithDelta(column) {
        return this.withDelta ? column.search(/delta_/i) !== -1 : column.search(/delta_/i) === -1;
    }

    getMessages(msgType, tab, type = 'device') {
        return new Observable<any>((observer) => {
            if (this.chart !== undefined) {
                this.chart.dispose();
            }
            if (type === 'device') {
                this.tab === 'archives' ? (this.msgGroup = 0) : this.msgGroup;
                this.devicesService
                    .getMessages(
                        this.device.id,
                        msgType,
                        this.deviceType === 'gateway' ? this.dateForm.startDate : this.devicesService.dateForm.startDate,
                        this.deviceType === 'gateway' ? this.dateForm.stopDate : this.devicesService.dateForm.stopDate,
                        this.msgGroup,
                        10000,
                        tab || null,
                        false,
                        undefined,
                        'device',
                        true,
                        true,
                        false,
                        true
                    )
                    .pipe(takeUntil(this.destroyed$))
                    .subscribe(
                        (messages) => {
                            if (!messages.data.length || !messages.fields_headers.length) {
                                setTimeout(() => {
                                    this.isSpinnerVisible = false;
                                }, 500);
                                return;
                            }
                            this.currentMessages = messages;
                            this.dataSource.data = messages.data;
                            const title = this.translate.instant('output_indications.message_api_true');
                            const calculationMethodTitle = this.translate.instant('output_indications.calculation_method');
                            const calculationBasicsTitle = this.translate.instant('output_indications.calculation_basics');
                            this.dataSource.data.forEach((el: any) => {
                                if (el?.message_api || el?.message_api === 0) {
                                    el['title'] = title;
                                    el['methodToDisplay'] = el.method ? `${calculationMethodTitle}: ${el.method}` : '';
                                    el['reasonToDisplay'] = el.reason ? `${calculationBasicsTitle} : ${el.reason}` : '';
                                }
                            });
                            this.availableColumns = this.sortFields(messages.fields_headers);
                            this.availableColumns = this.availableColumns.filter(
                                (el) => el !== 'event_name_en' && el !== 'reason' && el !== 'message_api' && el !== 'method'
                            );
                            this.settingsColumns = this.availableColumns.filter((column) => {
                                return (
                                    column.search(/delta_/i) === -1 &&
                                    column.search(/_kkt/i) === -1 &&
                                    column.search(/_loss_factor/i) === -1 &&
                                    column.search(/start_/i) === -1 &&
                                    column.search(/end_/i) === -1 &&
                                    column.search(/realdatetime/i) === -1
                                );
                            });
                            const settingsFromCloud = this.settings?.[this.deviceType + 'Fields']?.['model_id_' + this.device.model.id]?.[this.tab];
                            const visibleColumnsFromCloudSettings = settingsFromCloud
                                ? settingsFromCloud.map((c) => this.cleanMessageTitleFromPrefixes(c))
                                : null;
                            this.setDisplayedColumns = this.sortFields(
                                _.clone(visibleColumnsFromCloudSettings ?? this.settingsColumns),
                                this.availableColumns
                            );
                            if (this.device.model.tabs?.[this.tab]?.['chart_fields']?.[0]) {
                                if (this.isVectorChart && this.dataSource.data[this.dataSource.data?.length - 1]) {
                                    this.selectedMessage = this.dataSource.data[this.dataSource.data?.length - 1];
                                    setTimeout(() => {
                                        this.setSelectedMessage(this.dataSource.data[this.dataSource.data?.length - 1]);
                                    }, 500);
                                } else {
                                    this.reRenderChart(messages);
                                }
                            } else {
                                this.showChart = false;
                            }
                            observer.next(null);
                            observer.complete();
                        },
                        (error) => {
                            throw error;
                        }
                    );
            } else if (type === 'accounting_point') {
                this.devicesService
                    .getMessages(
                        this.accountingPoint.id,
                        msgType,
                        this.devicesService.dateForm.startDate,
                        this.devicesService.dateForm.stopDate,
                        this.msgGroup,
                        10000,
                        tab || null,
                        null,
                        'accounting_point',
                        'device',
                        true,
                        true,
                        false,
                        true
                    )
                    .pipe(takeUntil(this.destroyed$))
                    .subscribe(
                        (messages) => {
                            if (messages.data.length === 0 || messages.fields_headers.length === 0) {
                                setTimeout(() => {
                                    this.isSpinnerVisible = false;
                                }, 500);
                                return;
                            }
                            this.currentMessages = messages;
                            this.dataSource.data = messages.data;
                            this.availableColumns = this.sortFields(messages.fields_headers);
                            this.availableColumns = this.availableColumns.filter((el) => !this.forbiddenColumns.includes(el));
                            this.settingsColumns = this.availableColumns.filter((column) => {
                                return (
                                    column.search(/delta_/i) === -1 &&
                                    column.search(/_kkt/i) === -1 &&
                                    column.search(/_loss_factor/i) === -1 &&
                                    column.search(/start_/i) === -1 &&
                                    column.search(/end_/i) === -1 &&
                                    column.search(/realdatetime/i) === -1
                                );
                            });
                            const settingsFromCloud = this.settings?.[this.deviceType + 'Fields']?.['model_id_' + this.device.model.id]?.[this.tab];
                            const visibleColumnsFromCloudSettings = settingsFromCloud
                                ? settingsFromCloud.map((c) => this.cleanMessageTitleFromPrefixes(c))
                                : null;
                            this.setDisplayedColumns = this.sortFields(
                                _.clone(visibleColumnsFromCloudSettings ?? this.settingsColumns),
                                this.availableColumns
                            );
                            if (this.accountingPoint?.tabs?.[this.tab]?.['chart_fields']?.[0]) {
                                if (this.isVectorChart && this.dataSource.data[this.dataSource.data?.length - 1]) {
                                    this.selectedMessage = this.dataSource.data[this.dataSource.data?.length - 1];
                                    setTimeout(() => {
                                        this.setSelectedMessage(this.dataSource.data[this.dataSource.data?.length - 1]);
                                    }, 500);
                                } else {
                                    this.reRenderChart(messages);
                                }
                            } else {
                                this.showChart = false;
                            }
                            observer.next(null);
                            observer.complete();
                        },
                        (error) => {
                            throw error;
                        }
                    );
            }
        });
    }

    reRenderChart(messages) {
        this.prepareMessagesForChart(
            messages.fields_headers.filter((c) => this.isColumnWithKkt(c) && this.isColumnWithLoss(c)),
            messages.data
        ).then((chartData: any) => {
            if (chartData.inputs.length > 0) {
                this.showChart = true;
                setTimeout(() => {
                    this.renderChart(chartData, 'deviceChart');
                }, 500);
            } else {
                this.showChart = false;
            }
        });
    }

    sortFields(columns, mainArr = null) {
        if (+this.device?.model_id === 447) {
            const index = columns.findIndex((currentValue) => currentValue === 'starting_value');
            if (index !== -1) {
                columns.splice(index, 1);
            }
        }
        if (_.isNull(mainArr)) {
            mainArr = SETTING_DEVICE;
        }
        const result = mainArr.filter(function (field) {
            return columns.indexOf(field) !== -1;
        });
        return result.concat(columns.filter((el) => !result.includes(el)));
    }

    getFormattedDate(value) {
        const timeZone = (this.device.deviceTimezone >= 0 ? '+' : '-') + ('0' + Math.abs(this.device.deviceTimezone)).slice(-2) + '00';
        return moment.unix(value).utcOffset(timeZone).format('DD.MM.YYYY HH:mm');
    }

    typeof(value) {
        return typeof value;
    }

    async prepareMessagesForChart(inputs, messages): Promise<any> {
        return new Promise<any>((resolve) => {
            try {
                const data = {series: [], labels: [], inputs: []};
                if (this.device !== undefined && this.device !== null) {
                    const chartType = _.get(this.settings, 'chartType.model_id_' + this.device?.model?.id, 'line');
                    for (const input of inputs) {
                        let index;
                        const field = this.fieldsTitles[input] ? this.fieldsTitles[input] : input;
                        let name = field.title ?? field;
                        name += !_.isNull(_.get(this.fieldsTitles[input], 'unit.title', null))
                            ? ' ,' + this.fieldsTitles[input].unit?.title
                            : '';
                        if (this.device?.model?.tabs?.[this.tab]?.chart_fields?.length) {
                            if (
                                data.inputs.indexOf(input) === -1 &&
                                this.device.model.tabs[this.tab].chart_fields.indexOf(input) !== -1
                            ) {
                                data.inputs.push(name);
                                index = data.series.push(this.getMarkPointWithDetails(name, chartType));
                            }
                        } else {
                            if (
                                data.inputs.indexOf(input) === -1
                                && input.search(/delta/i) !== -1
                            ) {
                                data.inputs.push(name);
                                index = data.series.push(this.getMarkPointWithDetails(name, chartType));
                            }
                        }

                        for (const message of messages) {
                            if (this.device?.model?.tabs?.[this.tab]?.chart_fields?.length) {
                                if (this.device.model.tabs[this.tab].chart_fields.indexOf(input) !== -1) {
                                    data.series[index - 1].data.push(parseFloat(message[input]).toFixed(3));
                                }
                            } else {
                                if (input.search(/delta/i) !== -1) {
                                    data.series[index - 1].data.push(parseFloat(message[input]).toFixed(3));
                                }
                            }
                        }
                    }
                } else if (this.accountingPoint !== null && this.accountingPoint !== undefined) {
                    const chartType = 'line';
                    for (const input of inputs) {
                        let name = this.fieldsTitles[input] ? this.fieldsTitles[input].title : input;
                        name += !_.isNull(_.get(this.fieldsTitles[input], 'unit.title', null))
                            ? ' ,' + this.fieldsTitles[input].unit.title
                            : '';
                        let index;
                        if (this.accountingPoint?.tabs?.[this.tab]?.chart_fields?.length) {
                            if (
                                data.inputs.indexOf(input) === -1 &&
                                this.accountingPoint.tabs[this.tab].chart_fields.indexOf(input) !== -1
                            ) {
                                data.inputs.push(name);
                                index = data.series.push(this.getMarkPointWithDetails(name, chartType));
                            }
                        } else {
                            if (
                                data.inputs.indexOf(input) === -1
                                && input.search(/delta/i) !== -1
                            ) {
                                data.inputs.push(name);
                                index = data.series.push(this.getMarkPointWithDetails(name, chartType));
                            }
                        }

                        for (const message of messages) {
                            if (this.accountingPoint?.tabs?.[this.tab]?.chart_fields?.length) {
                                if (this.accountingPoint.tabs[this.tab].chart_fields.indexOf(input) !== -1) {
                                    data.series[index - 1].data.push(parseFloat(message[input]).toFixed(3));
                                }
                            } else {
                                if (input.search(/delta/i) !== -1) {
                                    data.series[index - 1].data.push(parseFloat(message[input]).toFixed(3));
                                }
                            }
                        }
                    }
                }
                this.generateLabels(messages).then(
                    (labels: any) => {
                        data.labels = this.unique(labels);
                        resolve(data);
                    },
                    (error) => {
                        throw error;
                    }
                );
            } catch (e) {
                throw e;
            }
        });
    }

    getMarkPointWithDetails(name, chartType) {
        return {
            name: name,
            data: [],
            type: chartType,
            markPoint: {
                data: [
                    {type: 'max', name: this.translate.instant('devices.data_show.max')},
                    {type: 'min', name: this.translate.instant('devices.data_show.min')}
                ],
                symbolSize: [100, 20],
                symbol: 'rect',
                symbolRotate: 'arrow'
            }
        };
    }

    async generateLabels(messages) {
        return new Promise((resolve) => {
            const labels = [];
            for (const message of messages) {
                labels.push(moment.unix(this.dateManipulations.dateLocal(message['datetime'])).format('DD.MM.YYYY HH:mm:ss'));
            }
            resolve(labels);
        });
    }

    unique(arr) {
        const obj = {};
        for (let i = 0; i < arr.length; i++) {
            const str = arr[i];
            obj[str] = true;
        }
        return Object.keys(obj);
    }

    renderChart(chartData: any, id: any) {
        const lang = this.lang;
        const dom = document.getElementById(id);
        if (dom === null) {
            return;
        }
        let chart = this.chart;
        chart = echarts.init(dom, {}, {renderer: 'canvas', height: 400});
        window.onresize = function () {
            chart.resize({
                width: 'auto'
            });
        };
        let selected = (this.device) ?
            _.get(this.settings, 'chartSelectedSeries.model_id_' + this.device.model.id, null)
            : chartData.inputs;
        let end = (chartData.labels > 5000) ? 30 : 100;

        if (this.standard && chartData.series[0]?.data) {
            chartData.series.push({
                name: this.translate.instant('devices.data_show.standard'),
                data: chartData.series[0]?.data.map(() => this.standard),
                type: 'line',
                lineStyle: {
                    color: '#00ACC1',
                    width: 2,
                    type: 'dotted'
                },
                itemStyle: {
                    color: '#00838F',
                    width: 1
                }
            });
            chartData.inputs.push(this.translate.instant('devices.data_show.standard'),);
        }

        const option = {
            color: [
                '#c62828',
                '#1565C0',
                '#2E7D32',
                '#F9A825',
                '#D84315',
                '#424242',
                '#37474F',
                '#AD1457',
                '#283593',
                '#4E342E',
                '#4527A0'
            ],
            toolbox: {
                top: '10%',
                right: '2%',
                show: true,
                feature: {
                    magicType: {
                        type: ['line', 'bar'],
                        title: {
                            line: this.translate.instant('devices.data_show.msg_9'),
                            bar: this.translate.instant('devices.data_show.msg_10')
                        }
                    },
                    saveAsImage: {
                        type: 'jpeg',
                        title: this.translate.instant('devices.data_show.msg_8'),
                        show: true,
                        name: this.getDeviceName()
                    }
                }
            },
            tooltip: {
                trigger: 'axis',
                confine: true,
                formatter: function (params) {
                    let content = '';
                    if (params.length > 15) {
                        for (let i = 0; i < 15; i++) {
                            content += params[i].marker + params[i].seriesName + '&nbsp;&nbsp;&nbsp;' + '<span style="float: right;"><b>' + params[i].value + '</b></span>' + '<br/>';
                        }
                        content += lang === 'ru' ? '<b>Выбрано слишком много параметров, отображаются первые 15 в списке</b>' : '<b>Too many parameters selected, the first 15 are displayed in the list</b>';
                    } else {
                        for (let i = 0; i < params.length; i++) {
                            content += params[i].marker + params[i].seriesName + '&nbsp;&nbsp;&nbsp;' + '<span style="float: right;"><b>' + params[i].value + '</b></span>' + '<br/>';
                        }
                    }
                    return params[0]?.axisValue + '<br/>' + content;
                }
            },
            legend: {
                right: 35,
                data: chartData.inputs,
                type: 'scroll',
                selected: _.isNull(selected) ? chartData.inputs : selected
            },
            grid: {
                left: '2%',
                right: '5%',
                bottom: '12%',
                containLabel: true
            },
            dataZoom: [
                {
                    type: 'inside',
                    start: 0,
                    end: end
                },
                {
                    start: 0,
                    end: 100,
                    handleIcon:
                        'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
                    handleSize: '80%',
                    handleStyle: {
                        color: '#fff',
                        shadowBlur: 3,
                        shadowColor: 'rgba(0, 0, 0, 0.6)',
                        shadowOffsetX: 2,
                        shadowOffsetY: 2
                    }
                }
            ],
            xAxis: {
                type: 'category',
                data: chartData.labels
            },
            yAxis: {
                type: 'value',
                scale: true
            },
            series: chartData.series
        };
        chart.setOption(option, true);
        if (this.device !== undefined && this.device !== null) {
            chart.on('legendselectchanged', (params) => {
                _.set(this.settings, 'chartSelectedSeries.model_id_' + this.device.model.id, params.selected);
                this._cloudService.settings = this.settings;
            });
            chart.on('magictypechanged', (params) => {
                _.set(this.settings, 'chartType.model_id_' + this.device.model.id, params.currentType);
                this._cloudService.settings = this.settings;
            });
        }
    }

    ngOnDestroy() {
        this.destroyed$.next(true);
        this.destroyed$.complete();
        if (this.chart) {
            this.chart.dispose();
        }
    }

    changeFieldVisible(field) {
        const displayedFields = _.cloneDeep(this.displayedColumns);
        if (displayedFields.indexOf(field) === -1) {
            displayedFields.push(field);
        } else {
            _.pull(displayedFields, field);
        }
        const sortedDisplayedFields = this.sortFields(displayedFields, this.availableColumns);
        this.setDisplayedColumns = sortedDisplayedFields.map((c) => this.addPrefixesToColumn(c));
        if (this.device) {
            _.set(this.settings, `${this.deviceType}Fields.model_id_${this.device.model.id}.${this.tab}`, this.displayedColumns);
            this._cloudService.settings = this.settings;
        }
    }

    getCheckedStatus(field) {
        if (!this.displayedColumns) {
            return false;
        }
        const cleanedDisplayColumns = this.displayedColumns.map((c) => this.cleanMessageTitleFromPrefixes(c));
        return cleanedDisplayColumns.indexOf(field) !== -1;
    }

    isDeltaFieldsAvailable() {
        return !!(this.availableColumns.filter((c) => c.search(/delta_/i) !== -1).length);
    }

    isKktFieldsAvailable() {
        return !!(this.availableColumns.filter((c) => c.search(/_kkt/i) !== -1).length);
    }

    isLossFieldsAvailable() {
        return !!(this.availableColumns.filter((c) => c.search(/_loss_factor/i) !== -1).length);
    }

    handlerChangeCheckboxRenderChart(filteredColumns) {
        this.prepareMessagesForChart(filteredColumns, this.dataSource.data).then((chartData: any) => {
            this.showChart = chartData.inputs.length > 0;
            if (this.showChart) {
                setTimeout(() => {
                    this.renderChart(chartData, 'deviceChart');
                }, 300);
            }
        });
    }

    getDeviceName() {
        const msg = this.lang !== 'ru' ? 'for device ' : 'по устройству ';
        const deviceName = this.device !== undefined && this.device !== null ? msg + this.device.name : '';
        return this.translate.instant('devices.data_show.msg_7', {
            deviceName
        });
    }

    toggleDeltaKktLoss($event: MatCheckboxChange, type) {
        if (type === 'kkt') {
            this.withKt = $event.checked;
        }
        if (type === 'loss') {
            this.withLoss = $event.checked;
        }
        if (type === 'delta') {
            this.withDelta = $event.checked;
        }

        const filteredForChartColumns = this.availableColumns.filter((column) => {
            return (
                column.search(/start_/i) === -1 &&
                column.search(/end_/i) === -1 &&
                column.search(/realdatetime/i) === -1 &&
                this.isColumnWithLoss(column) &&
                this.isColumnWithKkt(column)
            );
        });
        const isWithChartFields = this.device?.model?.tabs?.[this.tab]?.['chart_fields']?.[0]
            || this.accountingPoint?.tabs?.[this.tab]?.['chart_fields']?.[0];
        this.setDisplayedColumns = this.displayedColumns.map((c) => this.addPrefixesToColumn(c));

        if (isWithChartFields) {
            this.handlerChangeCheckboxRenderChart(filteredForChartColumns);
        } else {
            this.showChart = false;
        }
    }

    renderSumVectorDiagram(message) {
        const dom = document.getElementById('deviceVectorChartSum');
        const series = [];
        const angle = (Math.acos(message?.cos0) * (180 / Math.PI)).toFixed(2);
        series.push({
            coordinateSystem: 'polar',
            name: 'S',
            type: 'line',
            itemStyle: {
                color: '#64B5F6'
            },
            lineStyle: {
                width: 5
            },
            markPoint: {
                data: [
                    {
                        type: 'max'
                    }
                ],
                symbol: 'arrow',
                symbolSize: 20,
                symbolRotate: -parseFloat(angle) - 30,
                symbolOffset: [0, 0],
                label: {show: true, color: '#263238', formatter: 'S'}
            },
            data: [
                [0, 0],
                [1, parseFloat(angle)]
            ]
        });
        if (dom === null) {
            return;
        }
        const option = {
            legend: {
                show: false,
                data: ['S'],
                orient: 'vertical'
            },
            polar: {},
            tooltip: {
                show: false,
                trigger: 'axis',
                axisPointer: {
                    type: 'cross'
                }
            },
            angleAxis: {
                type: 'value',
                startAngle: 0,
                max: 360,
                clockwise: false
            },
            radiusAxis: {},
            series: series
        };
        this.chart = echarts.init(dom, {}, {renderer: 'canvas', height: 400, width: window.innerWidth / 2 - 50});
        this.chart.setOption(option, true);
    }

    setSelectedMessage(message: any) {
        if (this.tab !== 'instant_readings') {
            return;
        }
        this.selectedMessage = message;
        const dom = document.getElementById('deviceVectorChart');
        const series = [];
        const maxVoltage = Math.max(message?.voltage1, message?.voltage2, message?.voltage3);
        const maxCurrent = Math.max(message?.current1, message?.current2, message?.current3);
        series.push({
            coordinateSystem: 'polar',
            name: 'Ua',
            type: 'line',
            itemStyle: {
                color: '#FFD54F'
            },
            lineStyle: {
                width: 5
            },
            markPoint: {
                data: [
                    {
                        type: 'max'
                    }
                ],
                symbol: 'arrow',
                symbolSize: 20,
                symbolRotate: 0,
                symbolOffset: [0, 0],
                label: {show: true, color: '#263238', formatter: 'Ua'}
            },
            data: [
                [0, 0],
                [(message?.voltage1 / maxVoltage).toFixed(2), 0]
            ]
        });
        if (this.device.device_type_id === 3 || this.device.device_type_id === 29) {
            if (message?.cos1 && message?.current1) {
                const angle1 = (Math.acos(message?.cos1) * (180 / Math.PI)).toFixed(2);
                series.push({
                    coordinateSystem: 'polar',
                    name: 'Ia',
                    type: 'line',
                    itemStyle: {
                        color: '#FFD54F'
                    },
                    markPoint: {
                        data: [
                            {
                                type: 'max'
                            }
                        ],
                        symbol: 'arrow',
                        symbolSize: 20,
                        symbolRotate: parseFloat(angle1),
                        symbolOffset: [0, 0],
                        label: {show: true, color: '#263238', formatter: 'Ia'}
                    },
                    data: [
                        [0, 0],
                        [(message?.current1 / maxCurrent).toFixed(2), angle1]
                    ]
                });
            }
            if (message?.coruu1 && message?.voltage1) {
                series.push({
                    coordinateSystem: 'polar',
                    name: 'Ub',
                    type: 'line',
                    itemStyle: {
                        color: '#81C784'
                    },
                    lineStyle: {
                        width: 5
                    },
                    markPoint: {
                        data: [
                            {
                                type: 'max'
                            }
                        ],
                        symbol: 'arrow',
                        symbolSize: 20,
                        symbolRotate: message?.coruu1,
                        symbolOffset: [0, 0],
                        label: {show: true, color: '#263238', formatter: 'Ub'}
                    },
                    data: [
                        [0, 0],
                        [(message?.voltage1 / maxVoltage).toFixed(2), message?.coruu1]
                    ]
                });
                const angle2 = (Math.acos(message?.cos2) * (180 / Math.PI)).toFixed(2);
                series.push({
                    coordinateSystem: 'polar',
                    name: 'Ib',
                    type: 'line',
                    itemStyle: {
                        color: '#81C784'
                    },
                    markPoint: {
                        data: [
                            {
                                type: 'max'
                            }
                        ],
                        symbol: 'arrow',
                        symbolSize: 20,
                        symbolRotate: parseFloat(angle2) + parseFloat(message?.coruu1),
                        symbolOffset: [0, 0],
                        label: {show: true, color: '#263238', formatter: 'Ib'}
                    },
                    data: [
                        [0, 0],
                        [(message?.current2 / maxCurrent).toFixed(2), parseFloat(angle2) + parseFloat(message?.coruu1)]
                    ]
                });
            }
            if (message?.coruu2 && message?.voltage2) {
                series.push({
                    coordinateSystem: 'polar',
                    name: 'Uc',
                    type: 'line',
                    itemStyle: {
                        color: '#e57373'
                    },
                    lineStyle: {
                        width: 5
                    },
                    markPoint: {
                        data: [
                            {
                                type: 'max'
                            }
                        ],
                        symbol: 'arrow',
                        symbolSize: 20,
                        symbolRotate: message?.coruu2,
                        symbolOffset: [0, 0],
                        label: {show: true, color: '#263238', formatter: 'Uc'}
                    },
                    data: [
                        [0, 0],
                        [(message?.voltage2 / maxVoltage).toFixed(2), message?.coruu2]
                    ]
                });
                const angle3 = (Math.acos(message?.cos3) * (180 / Math.PI)).toFixed(2);
                series.push({
                    coordinateSystem: 'polar',
                    name: 'Ic',
                    itemStyle: {
                        color: '#e57373'
                    },
                    type: 'line',
                    markPoint: {
                        data: [
                            {
                                type: 'max'
                            }
                        ],
                        symbol: 'arrow',
                        symbolSize: 20,
                        symbolRotate: parseFloat(angle3) + parseFloat(message?.coruu2),
                        symbolOffset: [0, 0],
                        label: {show: true, color: '#263238', formatter: 'Ic'}
                    },
                    data: [
                        [0, 0],
                        [(message?.current3 / maxCurrent).toFixed(2), parseFloat(angle3) + parseFloat(message?.coruu2)]
                    ]
                });
            }
        } else {
            if (message?.cos1 && message?.current1) {
                const angle1 = (Math.acos(message?.cos1) * (180 / Math.PI)).toFixed(2);
                series.push({
                    coordinateSystem: 'polar',
                    name: 'Ia',
                    itemStyle: {
                        color: '#FFD54F'
                    },
                    type: 'line',
                    markPoint: {
                        data: [
                            {
                                type: 'max'
                            }
                        ],
                        symbol: 'arrow',
                        symbolSize: 20,
                        symbolRotate: parseFloat(angle1),
                        symbolOffset: [0, 0],
                        label: {show: true, color: '#263238', formatter: 'Ia'}
                    },
                    data: [
                        [0, 0],
                        [(message?.current1 / maxCurrent).toFixed(2), angle1]
                    ]
                });
            }
        }

        if (dom === null) {
            return;
        }
        const option = {
            legend: {
                show: false,
                data: ['Ua, Ub, Uc, Ia, Ib, Ic'],
                orient: 'vertical'
            },
            polar: {},
            tooltip: {
                show: false,
                trigger: 'axis',
                axisPointer: {
                    type: 'cross'
                }
            },
            angleAxis: {
                type: 'value',
                startAngle: 90,
                max: 360,
                clockwise: false
            },
            radiusAxis: {},
            series: series
        };
        this.chart = echarts.init(dom, {}, {renderer: 'canvas', height: 400, width: window.innerWidth / 2 - 50});
        this.chart.setOption(option, true);
        this.renderSumVectorDiagram(message);
    }

    toggleVectorChartHandler($event: MatSlideToggleChange) {
        this.isSpinnerVisible = true;
        if (!this.dataSource.data[this.dataSource.data?.length - 1]) {
            return;
        }
        this.isVectorChart = $event.checked;
        if ($event.checked) {
            this.reRenderChart(this.currentMessages);
            this.isSpinnerVisible = false;
            this.selectedMessage = this.dataSource.data[this.dataSource.data?.length - 1];
            setTimeout(() => {
                this.setSelectedMessage(this.dataSource.data[this.dataSource.data?.length - 1]);
            }, 500);
        } else {
            delete this.selectedMessage;
            setTimeout(() => {
                this.reRenderChart(this.currentMessages);
                this.isSpinnerVisible = false;
            }, 500);
        }
    }

    refreshDataHandler() {
        this.isSpinnerVisible = true;
        this.refreshData({msgType: this.msgType, tab: this.tab});
    }

    downloadExcel() {
        const sortConfig = this.dataSource.sort;
        const messages = _.orderBy(_.cloneDeep(this.dataSource.data), [sortConfig.active], [sortConfig.direction || 'desc']);
        let displayedFields = _.cloneDeep(this.displayedColumns).filter(
            (c) => c != 'delete_message' && c !== 'reason' && c !== 'message_api' && c !== 'method'
        );
        displayedFields = this.sortFields(displayedFields, this.availableColumns);
        const workbook = new Excel.Workbook();
        const invalidCharacters = /[*?:\/[\]]/g;
        const correctedName = this.device.name.replace(invalidCharacters, '_');
        const worksheet = workbook.addWorksheet(correctedName, {
            pageSetup: {fitToPage: true, fitToHeight: 5, fitToWidth: 7}
        });
        const headers = [];
        _.each(displayedFields, (column) => {
            headers.push(this.fieldsTitles[column]?.title || column);
        });

        const headerRow = worksheet.addRow(headers);
        headerRow.eachCell((cell) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {argb: 'FFDDDDDD'},
                bgColor: {argb: 'FFFFFFFF'}
            };
            cell.border = {
                top: {style: 'thin'},
                left: {style: 'thin'},
                bottom: {style: 'thin'},
                right: {style: 'thin'}
            };
            cell.alignment = {wrapText: true, indent: 1};
        });
        _.each(messages, (message: any) => {
            const rowArr = [];
            for (const key of displayedFields) {
                if (key === 'datetime' || key === 'realdatetime' || key === 'created_at') {
                    rowArr.push(this.getFormattedDate(message[key]));
                } else {
                    rowArr.push(message[key] !== undefined && message[key] !== null ? message[key] : '-');
                }
            }
            const newRow = worksheet.addRow(rowArr);
            newRow.eachCell((cell) => {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {argb: 'FFFFFFFF'},
                    bgColor: {argb: 'FFFFFFFF'}
                };
                cell.border = {
                    top: {style: 'thin'},
                    left: {style: 'thin'},
                    bottom: {style: 'thin'},
                    right: {style: 'thin'}
                };
                cell.alignment = {wrapText: true, indent: 1};
                // cell.alignment = { indent: 1 };
            });
        });
        _.each(displayedFields, function (column, index) {
            worksheet.getColumn(index + 1).width = 25;
        });
        const fileName = this.translate.instant('devices.data_show.msg_6', {deviceName: this.device.name});
        workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });
            fs.saveAs(
                blob,
                `${fileName} ${moment.unix(this.dateManipulations.dateLocal(this.devicesService.dateForm.startDate)).utc().format('DD.MM.YYYY')}
                     ${this.translate.instant('devices.data.msg_4')}
                     ${moment.unix(this.dateManipulations.dateLocal(this.devicesService.dateForm.stopDate)).utc().format('DD.MM.YYYY')}.xlsx`
            );
        });
    }

    onDeleteMessage(message) {
        const reqData = {
            datetime: message.datetime,
            msgType: this.msgType,
            device_id: this.device.id
        };
        const dialogRef = this.dialog.open(ConfirmPopupComponent, {
            width: '400px',
            data: {
                type: 'delete-message',
                reqData
            }
        });
        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.destroyed$))
            .subscribe((data) => {
                if (data && data.result === 'success') {
                    this.currentMessages.data = this.currentMessages.data.filter((el) => el.datetime !== message.datetime);
                    this.dataSource.data = this.dataSource.data.filter((el: any) => el.datetime !== message.datetime);
                }
            });
    }

    getMessageApiInfo(message) {
        return `${message?.title}\n${message?.reasonToDisplay}\n${message?.methodToDisplay}`;
    }

    downloadRawFile(datetime, messageElement) {
        const rawData = messageElement.toUpperCase();
        const binary = [];
        for (let i = 0; i < rawData.length / 2; i++) {
            const h = rawData.substr(i * 2, 2);
            binary[i] = parseInt(h, 16);
        }
        const event = new MouseEvent('click', {
            view: window,
            bubbles: false,
            cancelable: true
        });
        const a = document.createElement('a');
        a.target = '_blank';
        a.href = URL.createObjectURL(new Blob([new Uint8Array(binary)], {type: 'application/octet-stream'}));
        a.download = `${this.device.id}_${moment(datetime * 1000).format('DD-MM-YYYY')}.mds`;
        a.dispatchEvent(event);
    }
}
