import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, Subject } from 'rxjs';
import * as moment from 'moment';
import { State, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { PersonalDesignConfig } from '@core/models/app.core.models';
import { UsersApiService } from '@core/services/api/users-api.service';
import { DirectoriesActions } from '@store/directories/directories.action';
import { ModelsActions } from '@store/models/models.actions';
import * as TitlesActions from '@store/actions/titles.action';
import { DevicesApiService } from '@core/services/api/devices-api.service';
import { ReportTemplatesActions } from '@store/reportTemplates/reportTemplates.actions';

@Injectable({
    providedIn: 'root'
})
export class CloudService {
    personalDesign: PersonalDesignConfig | null = null;
    token: string;
    sessionSettings = new BehaviorSubject({});
    appSubject = new Subject();
    appVar;
    settingsSubject = new Subject();
    enterFromAdminB2B = false;
    loggedInSuccess$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private settingsApp = null;

    constructor(private devicesApiService: DevicesApiService, private store: Store, private usersApiService: UsersApiService) {}

    private _current_company: any = null;

    get current_company() {
        return this._current_company;
    }

    set current_company(value: any) {
        this._current_company = value;
    }

    private _user: any = null;

    get user() {
        return this._user;
    }

    set user(value: any) {
        this._user = value;
    }

    set app(app) {
        this.appVar = app;
        this._current_company = app?.current_company;
        this._user = app?.user;
        this.appSubject.next(app);
        try {
            localStorage.setItem('app', JSON.stringify(app));
        } catch {}
    }

    get settings() {
        return new Observable((observer) => {
            observer.next(this.settingsApp);
            observer.complete();
        });
    }

    set settings(v: any) {
        const settings = Array.isArray(v) && v?.length === 0 ? {} : v;
        this.settingsApp = settings;
        this.settingsSubject.next(settings);
        this.usersApiService.updateUserSettings({ settings }).subscribe();
    }

    get appConst(): Observable<any> {
        return new Observable((observer) => {
            observer.next(this.appVar);
            observer.complete();
        });
    }

    get getApp() {
        return this.appVar;
    }

    static setLanguage(translate: TranslateService) {
        const browserLang = localStorage.getItem('language') ?? translate.getBrowserLang();
        translate.use(browserLang.match(/en|ru|mn/i) ? browserLang : 'en');
    }

    setPersonalDesign(data: PersonalDesignConfig | null) {
        this.personalDesign = data;
    }

    fillStore() {
        this.store.dispatch(ReportTemplatesActions.fetchbalancetemplates());
        this.store.dispatch(ReportTemplatesActions.fetchgrouptemplates());
        this.store.dispatch(ReportTemplatesActions.fetchsingletemplates());
        this.store.dispatch(ReportTemplatesActions.fetchsystemtemplates());
        // TODO: move all methods below to fetch
        forkJoin([
            this.devicesApiService.devices_groups(),
            this.devicesApiService.allInterfaces(),
            this.devicesApiService.getGroupsMessage(),
            this.devicesApiService.getMeteringDeviceModels(),
            this.devicesApiService.getGatewaysModels(),
            this.devicesApiService.getBaseStationModels(),
            this.devicesApiService.getDeviceFieldTitles(),
            this.devicesApiService.getBrands(),
            this.devicesApiService.getMeteringDeviceModels({ company_id: this.current_company.id }),
            this.devicesApiService.getAllMsgTypes()
        ]).subscribe(
            ([
                resDeviceGroups,
                resInterfaces,
                resGroupsMessage,
                resDeviceModels,
                resGateway,
                resBaseStation,
                device_fields_titles,
                resBrands,
                resCompanyDeviceModels,
                resMsgTypes
            ]) => {
                this.store.dispatch(DirectoriesActions.loaddevicegroups({ payload: resDeviceGroups?.data?.device_groups }));
                this.store.dispatch(DirectoriesActions.loadinterfaces({ payload: resInterfaces?.data?.interfaces }));
                resGroupsMessage.splice(1, 0, ...resGroupsMessage.splice(4, 1));
                this.store.dispatch(DirectoriesActions.loadmessagegroups({ payload: resGroupsMessage }));
                this.store.dispatch(DirectoriesActions.loadmessagetypes({ payload: resMsgTypes?.data?.device_messages_types }));
                this.store.dispatch(ModelsActions.loadmeteringdevicemodels({ payload: resDeviceModels?.data?.metering_device_models }));
                this.store.dispatch(ModelsActions.loadgatewaymodels({ payload: resGateway?.data?.gateway_models }));
                this.store.dispatch(ModelsActions.loadbasestationmodels({ payload: resBaseStation?.data?.base_station_models }));
                this.store.dispatch(TitlesActions.loadDeviceFieldsTitles({ deviceFieldsTitles: device_fields_titles.data.fields }));
                this.store.dispatch(DirectoriesActions.loadbrands({ payload: resBrands?.data?.device_brands }));
                this.store.dispatch(
                    ModelsActions.loadcompanymeteringdevicemodels({ payload: resCompanyDeviceModels?.data?.metering_device_models })
                );
            }
        );
    }
}

export class Buttons {
    name: string;
    icon: string;
    route: string;
    click: string;
}

/**
 @deprecated будет удалено (не используется)
 */
@Injectable()
export class ButtonsService {
    public buttons = new BehaviorSubject('');

    constructor() {}

    setButtons(buttons) {
        this.buttons.next(buttons);
    }
}

/**
 @deprecated будет удалено (не используется)
 */
@Injectable()
export class TitleService {
    public title = new BehaviorSubject(' ');

    constructor() {}

    setTitle(title) {
        this.title.next(title);
    }
}

/**
 @deprecated будет удалено (не используется), вместо него использовать check-access.service.ts
 */
// DELETE when all instances will be deleted
@Injectable({
    providedIn: 'root'
})
export class CheckAccess {
    link: any = null;

    constructor(private state: State<{}>) {}

    listAccess(rule, module_id) {
        const rules = this.state.getValue();
        if (rules?.access?.access_group) {
            return rules.access.access_group.access_rules[rule].includes(module_id);
        } else if (rules.access.is_admin) {
            return true;
        }
    }
}

/**
 @deprecated будет удалено (не используется), вместо него использовать date.service.ts
 */
@Injectable({
    providedIn: 'root'
})
export class DateManipulations {
    subscribers = [];
    appVar;
    appSubject = new Subject();

    constructor() {}

    set app(app) {
        this.appVar = app;
        this.appSubject.next(app);
    }

    static dateUTCWithTz(date, tz) {
        const currentTimeZoneOffsetInHours = -new Date().getTimezoneOffset() / 60;
        return date + (currentTimeZoneOffsetInHours - +tz) * 3600;
    }

    static dateUTCWithOutTz(date, tz) {
        const currentTimeZoneOffsetInHours = -new Date().getTimezoneOffset() / 60;
        return moment.unix(date).unix() - (currentTimeZoneOffsetInHours - +tz) * 3600;
    }

    // utc-4 -> 0
    dateUTC(date?) {
        let result;
        let utcDate;
        if (date !== undefined) {
            utcDate = moment.unix(date).utc().unix();
        } else {
            utcDate = moment.utc().unix();
        }
        result = utcDate - this.appVar.currentTimeZone * 3600;
        return result;
    }

    expiresDate(date) {
        let utcDate;
        let result;
        utcDate = moment.unix(date).utc().unix();
        result = utcDate - this.appVar.currentTimeZone * 3600;
        return result;
    }

    // 0 -> utc-4
    dateLocal(date?) {
        let result;
        let utcDate;
        if (date !== undefined) {
            utcDate = moment.unix(date).utc().unix();
        } else {
            utcDate = moment.utc().unix();
        }
        result = utcDate + this.appVar.currentTimeZone * 3600;
        return result;
    }
}
