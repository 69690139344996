import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { UsersApiService } from '@core/services/api/users-api.service';

declare var navigator: any;

// TODO - move initialisation of service to app.component
// DELETE - when all usages will be eliminated

/**
 @deprecated - будет удален в конце рефакторинга,
  вместо него использовать базовый TranslateService из @ngx-translate/core
 */

@Injectable({
    providedIn: 'root'
})
export class TranslateLanguageService {
    public currentLanguage$: BehaviorSubject<string> = new BehaviorSubject<string>('en');
    // TODO: move to core/const
    public availableLanguages = ['ru', 'en', 'mn'];

    constructor(public translateService: TranslateService, private usersApiService: UsersApiService) {}

    public updateCurrentLanguage(lng: string, update = true, setSystemLanguage = false): void {
        this.translateService.use(lng);
        this.currentLanguage$.next(lng);
        localStorage.setItem('language', lng);
        if (update) {
            this.usersApiService.getUserSystemLanguage(lng).subscribe(() => {
                window.location.reload();
            });
        } else if (setSystemLanguage) {
            this.usersApiService.getUserSystemLanguage(lng).subscribe();
        }
    }

    useLanguage(lang) {
        this.translateService.use(lang);
    }

    languageSet() {
        this.translateService.addLangs(this.availableLanguages);
        this.translateService.setDefaultLang('en');
        // TODO : use translateService.getBrowserDefault instead of navigator
        let currentLanguage = localStorage.getItem('language') || navigator.language || navigator.userLanguage;
        if (!currentLanguage || !this.availableLanguages.includes(currentLanguage)) {
            currentLanguage = 'en';
            localStorage.setItem('language', currentLanguage);
        }
        this.currentLanguage$.next(currentLanguage);
        this.translateService.use(currentLanguage);
    }

    public translateInstant(key: string, data = null) {
        return this.translateService.instant(key, data);
    }
}
