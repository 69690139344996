import { Injectable } from '@angular/core';
import { PageSetup as ExcelJsPageSetup, Style as ExcelJsStyle, Workbook } from 'exceljs';

export const XLS_DEFAULT_STYLE: XlsStyle = {
    pageSetup: { fitToPage: true, fitToHeight: 5, fitToWidth: 7 },
    header: {
        fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFDDDDDD' },
            bgColor: { argb: 'FFFFFFFF' }
        },
        border: {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
        },
        alignment: { indent: 1, horizontal: 'center', vertical: 'middle' }
    },
    row: {
        alignment: { wrapText: true, indent: 1, horizontal: 'center' }
    }
};

// TODO: find other lib to save as Excel file
// TODO: add styles constants for typical styles in XLS

// TODO: move types to separate file if needed
export type ColumnDescription = {
    key: string;
    title: string;
    width?: number;
};

export type RowsData = {
    [key: string]: string | number | undefined | null;
};

export type XlsStyle = {
    pageSetup?: Partial<ExcelJsPageSetup>;
    header?: Partial<ExcelJsStyle>;
    row?: Partial<ExcelJsStyle>;
};

export type XLSFileData = {
    fileName: string;
    sheetName?: string;
    preHeaderRows?: RowsData[];
    columns: ColumnDescription[];
    rows: RowsData[];
    style?: XlsStyle;
};

export type XlsReadData = {
    fileName: string;
    columns: string[];
    rows: RowsData[];
};

/**
 * @description сервис для основной работы с файлами, особенно с XLS
 */

@Injectable({
    providedIn: 'root'
})
export class FileService {
    download(data: any, fileNameWithExtension: string) {
        const event = new MouseEvent('click', {
            view: window,
            bubbles: false,
            cancelable: true
        });
        const a = document.createElement('a');
        a.target = '_blank';
        a.href = URL.createObjectURL(data);
        a.download = fileNameWithExtension;
        a.dispatchEvent(event);
    }

    downloadAsXLS(fileData: XLSFileData) {
        this.getBlobForXlsFile(fileData).then((blob) => {
            this.download(blob, fileData.fileName);
        });
    }

    async getBlobForXlsFile(fileData: XLSFileData, withoutEmptyCells = true): Promise<Blob> {
        const emptyCellValue = withoutEmptyCells ? '-' : '';
        const workbook = new Workbook();
        const validatedSheetName = (sheetName:string) => {
            return sheetName.replace(/[\\\/\*\?\:\[\]]/g, '_');
        };
        const validSheetName = validatedSheetName(fileData?.sheetName ?? fileData.fileName);
        const worksheet = workbook.addWorksheet(validSheetName);
        let currentRowIndex = 1;

        if (fileData?.style?.pageSetup) {
            worksheet.pageSetup = fileData?.style?.pageSetup;
        }

        if (fileData.preHeaderRows && fileData.preHeaderRows.length > 0) {
            fileData.preHeaderRows.forEach((rowData) => {
                const row = worksheet.getRow(currentRowIndex);
                fileData.columns.forEach((col, colIndex) => {
                    row.getCell(colIndex + 1).value = rowData?.[col.key] ?? '';
                });
                currentRowIndex++;
            });
        }

        const headers = worksheet.getRow(currentRowIndex);

        fileData.columns.forEach((column, index) => {
            headers.getCell(index + 1).value = column.title ?? column.key;
        });

        if (fileData?.style?.header) {
            headers.eachCell((cell) => {
                cell.style = fileData?.style?.header;
            });
        }

        currentRowIndex++;

        fileData.rows.forEach((rowData) => {
            const row = worksheet.getRow(currentRowIndex);
            fileData.columns.forEach((col, colIndex) => {
                row.getCell(colIndex + 1).value = rowData?.[col.key] ?? emptyCellValue;
            });
            if (fileData?.style?.row) {
                row.eachCell((cell) => {
                    cell.style = fileData?.style?.row;
                });
            }
            currentRowIndex++;
        });

        worksheet.columns = fileData.columns;

        const bufferData = await workbook.xlsx.writeBuffer();
        return new Blob([bufferData], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });
    }

    async readXlsxFile(file: File): Promise<XlsReadData | null> {
        const workbook = new Workbook();
        const fileData = await file.arrayBuffer();
        const fileName = file.name;
        await workbook.xlsx.load(fileData);
        const worksheet = workbook.getWorksheet(1);
        if (!worksheet) {
            return null;
        }
        const columnsRow = worksheet.getRow(1);
        const columns = [];
        const rows = [];
        for (let i = 1; i <= worksheet.columnCount; i++) {
            const value = columnsRow.getCell(i).value;
            columns.push(this.getCellValue(value));
        }
        for (let i = 2; i <= worksheet.rowCount; i++) {
            const rowData: RowsData = {};
            const row = worksheet.getRow(i);
            columns.forEach((column, index) => {
                rowData[column] = this.getCellValue(row.getCell(index + 1).value);
            });
            rows.push(rowData);
        }
        return { fileName, columns, rows };
    }

    public getRowData(item: any, columns: ColumnDescription[]): RowsData {
        const result: RowsData = {};
        columns.forEach((column) => {
            result[column.key] = item?.[column.key] ?? '-';
        });
        return result;
    }

    private getCellValue(v: any): string | null {
        if (!v && v !== 0 && v !== '0') {
            return null;
        }
        if (String(v).trim() === 'null' || !String(v).trim()) {
            return null;
        }
        if (typeof v === 'string') {
            return v.trim();
        }
        return String(v).trim();
    }
}
