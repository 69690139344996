import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PassportService } from 'src/app/auth/passport.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CompaniesApiService {
    private _baseURL: string;

    constructor(private _http: HttpClient, private auth: PassportService) {
        this._baseURL = environment.baseURL;
    }

    getSubCompanies(body) {
        return this._http.post(this._baseURL + 'company/subcompanies', body, { headers: this.getHeaders() });
    }

    listUsers(body) {
        return this._http.post(this._baseURL + 'company/users', body, { headers: this.getHeaders() });
    }

    addCompanyWithNewUser(company) {
        return this._http.post(this._baseURL + 'company/create', company, { headers: this.getHeaders() });
    }

    // TODO: check need headers or not
    getCompaniesTypes() {
        return this._http.get(this._baseURL + 'company/types');
    }

    enterInCompany(body) {
        return this._http.put(this._baseURL + 'company/subcompany/enter', body, { headers: this.getHeaders() });
    }

    exitFromCompany(body) {
        return this._http.put(this._baseURL + 'company/subcompany/leave', body, { headers: this.getHeaders() });
    }

    getCompaniesStatuses() {
        return this._http.get(this._baseURL + 'company/statuses', { headers: this.getHeaders() });
    }

    deleteCompany(id) {
        return this._http.delete(this._baseURL + 'company/subcompany/' + id, { headers: this.getHeaders() });
    }

    getUserTimezone() {
        return this._http.get(this._baseURL + 'user', { headers: this.getHeaders() });
    }

    getCompanyStatuses() {
        return this._http.get(this._baseURL + 'company/statuses', { headers: this.getHeaders() });
    }

    listCompanies() {
        return this._http.get(this._baseURL + 'company/subcompanies', { headers: this.getHeaders() });
    }

    updateCompany(body, id) {
        return this._http.put<any>(this._baseURL + 'company/subcompany/' + id, body, { headers: this.getHeaders() });
    }

    getSubCompany(id) {
        return this._http.get<any>(this._baseURL + 'company/subcompany/' + id, { headers: this.getHeaders() });
    }

    activeSwitch(body, id) {
        return this._http.put(this._baseURL + 'company/subcompany/switch/' + id, body, { headers: this.getHeaders() });
    }

    getListOfTariffs(body = {}) {
        return this._http.post(this._baseURL + 'statistics/new_billing/tariff_plan/list', body, { headers: this.getHeaders() });
    }

    // moved from profile.service
    saveCompanyInfo(data) {
        return this._http.put(this._baseURL + 'company', data, { headers: this.getHeaders() });
    }

    private getHeaders() {
        return new HttpHeaders().append('Access-Control-Allow-Origin', '*').append('Authorization', 'Bearer ' + this.auth.access_token);
    }
}
