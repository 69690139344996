<ng-container *ngIf="resizable; else fixedSearch">
    <div class="nekta-search" appResizable (widthChanged)="widthChanged.emit($event)" [ngStyle]="{'width.px': currentWidth}" [style.width.px]="getWidth()">
        <input class="nekta-search-input"
               matInput
               placeholder="{{ 'common.search' | translate }}"
               [(ngModel)]="searchString"
               (keyup.enter)="onClick()"
        />
        <div class="nekta-action-buttons">
            <i
                *ngIf="showExactSearch"
                [class]="'match-button--' + (!multiWord ? 'active' : 'disabled') + ' material-icons personal_design_icon'"
                [matTooltip]="'devices.listMeteringDevice.exact_search' | translate"
                (click)="toggleMultiWord()"
            >
                text_fields
            </i>

            <i *ngIf="searchString"
               class="close-button material-icons"
               [matTooltip]="'common.clear_field' | translate"
               (click)="onClear()"
            >
                close
            </i>
            <ng-container *ngIf="isAdminPanelOpen; else otherIcon">
                <i
                    class="search-button material-icons personal_design_icon"
                    [matTooltip]="multiWord ? ('common.search_extended' | translate) : undefined"
                    [ngClass]="{'disabled': !searchString}"
                    (click)="onClick()"
                >
                    search
                </i>
            </ng-container>
            <ng-template #otherIcon>
                <i
                    class="search-button material-icons personal_design_icon"
                    [matTooltip]="multiWord ? ('common.search_extended' | translate) : undefined"
                    (click)="onClick()"
                >
                    search
                </i>
            </ng-template>
        </div>
    </div>
</ng-container>

<ng-template #fixedSearch>
    <div class="nekta-search" [style.width.px]="getWidth()">
        <input class="nekta-search-input"
               matInput
               placeholder="{{ 'common.search' | translate }}"
               [(ngModel)]="searchString"
               (keyup.enter)="onClick()"
        />
        <div class="nekta-action-buttons">
            <i
                *ngIf="showExactSearch"
                [class]="'match-button--' + (!multiWord ? 'active' : 'disabled') + ' material-icons personal_design_icon'"
                [matTooltip]="'devices.listMeteringDevice.exact_search' | translate"
                (click)="toggleMultiWord()"
            >
                text_fields
            </i>

            <i *ngIf="searchString"
               class="close-button material-icons"
               [matTooltip]="'common.clear_field' | translate"
               (click)="onClear()"
            >
                close
            </i>
            <ng-container *ngIf="isAdminPanelOpen; else otherIcon">
                <i
                    class="search-button material-icons personal_design_icon"
                    [matTooltip]="multiWord ? ('common.search_extended' | translate) : undefined"
                    [ngClass]="{'disabled': !searchString}"
                    (click)="onClick()"
                >
                    search
                </i>
            </ng-container>
            <ng-template #otherIcon>
                <i
                    class="search-button material-icons personal_design_icon"
                    [matTooltip]="multiWord ? ('common.search_extended' | translate) : undefined"
                    (click)="onClick()"
                >
                    search
                </i>
            </ng-template>
        </div>
    </div>
</ng-template>

