import { PersonalDesign } from '@core/models/app.core.models';

export const PERSONAL_DESIGN_CONFIGS: PersonalDesign = {
    'service.iotpetro.spb.ru': {
        logo_cloud: '/assets/personal_design/img/logo-iotpetro-2.png',
        logo_auth: '/assets/personal_design/img/logo-iotpetro-1.png',
        custom_css: '/assets/personal_design/css/iot-petro.css',
        title: 'IOT PETRO'
    },
    'home.online-people.ru': {
        logo_cloud: '/assets/personal_design/img/logo-online-people.png',
        logo_auth: '/assets/personal_design/img/logo-online-people.png',
        custom_css: '/assets/personal_design/css/online-people.css',
        title: 'Интернет людей'
    },
    'iot-area.ru': {
        logo_cloud: '/assets/personal_design/img/logo-iot-area-2.png',
        logo_auth: '/assets/personal_design/img/logo-iot-area-1.png',
        custom_css: '/assets/personal_design/css/iot-area.css',
        title: 'IOT-AREA'
    },
    'lorawan.tomica.ru': {
        logo_cloud: '/assets/personal_design/img/tomica_logo_1.png',
        logo_auth: '/assets/personal_design/img/tomica_logo_2.png',
        custom_css: '/assets/personal_design/css/lorawan-tomica.css',
        custom_js: '/assets/personal_design/js/lorawan-tomica.js',
        title: 'LORAWAN TOMICA'
    },
    'cloud.smartnet.kg': {
        logo_cloud: '/assets/personal_design/img/cloud_smartnet_kg_2.png',
        logo_auth: '/assets/personal_design/img/cloud_smartnet_kg_1.png',
        custom_css: '/assets/personal_design/css/cloud_smartnet_kg.css',
        title: 'CLOUD SMARTNET'
    },
    'iot-server.ru': {
        logo_cloud: '/assets/personal_design/img/novouchet_1.png',
        logo_auth: '/assets/personal_design/img/novouchet_1.png',
        custom_css: '/assets/personal_design/css/iot-server.css',
        title: 'IOT-SERVER'
    },
    'quality.ertelecom.ru': {
        logo_cloud: '/assets/personal_design/img/er-telecom-cloud.svg',
        logo_auth: '/assets/personal_design/img/er-telecom-auth.svg',
        custom_css: '/assets/personal_design/css/er-telecom.css',
        title: 'QUALITY ERTELECOM'
    },
    'ascue.ertelecom.ru': {
        logo_cloud: '/assets/personal_design/img/er-telecom-cloud.svg',
        logo_auth: '/assets/personal_design/img/er-telecom-auth.svg',
        custom_css: '/assets/personal_design/css/ascue-er-telecom.css',
        demoUser: 'demo@nekta.cloud',
        demoPassword: 'qwertyqwerty',
        title: 'ASCUE ERTELECOM'
    },
    'polytarif2.ru': {
        logo_cloud: '/assets/personal_design/img/logo-spbzip-1.png',
        logo_auth: '/assets/personal_design/img/logo-spbzip-2.png',
        custom_css: '/assets/personal_design/css/spbzip.css',
        title: 'Polytarif'
    },
    'energoinvestspb-askue.ru': {
        logo_cloud: '/assets/personal_design/img/logo-spbzip-1.png',
        logo_auth: '/assets/personal_design/img/logo-spbzip-2.png',
        custom_css: '/assets/personal_design/css/spbzip.css',
        title: 'Polytarif'
    },
    'askue.spbgau.org': {
        logo_cloud: '/assets/personal_design/img/logo-spbzip-1.png',
        logo_auth: '/assets/personal_design/img/logo-spbzip-2.png',
        custom_css: '/assets/personal_design/css/spbzip.css',
        title: 'Polytarif'
    },
    'wodizmer.su': {
        logo_cloud: '/assets/personal_design/img/logo-wodizmer-1.png',
        logo_auth: '/assets/personal_design/img/logo-wodizmer-2.png',
        custom_css: '/assets/personal_design/css/wodizmer.css',
        title: 'WODIZMER'
    },
    'iot.tehnopost.pro': {
        logo_cloud: '/assets/personal_design/img/logo-tehnopost-1.png',
        logo_auth: '/assets/personal_design/img/logo-tehnopost-2.png',
        custom_css: '/assets/personal_design/css/tehnopost.css',
        title: 'IoT ТЕХНОПОСТ',
        favicon: '/assets/personal_design/img/favicon-tehnopost.png?v=2',
    },
    'sanau.tech': {
        logo_cloud: '/assets/personal_design/img/sanau_2.svg',
        logo_auth: '/assets/personal_design/img/sanau_1.svg',
        custom_css: '/assets/personal_design/css/sanau.css',
        title: 'ТОО "SANAU TECHNOLOGIES"',
        favicon: '/assets/personal_design/img/favicon_sanau.ico?v=2',
    },
    'iot.rcitsakha.ru': {
        logo_cloud: '/assets/personal_design/img/rcitsakha_2.png',
        logo_auth: '/assets/personal_design/img/rcitsakha_1.png',
        custom_css: '/assets/personal_design/css/rcitsakha.css',
        title: 'РЦИТ',
        favicon: '/assets/personal_design/img/rcitsakha_favicon.png?v=2',
    },
    'jsb.jet.su': {
        logo_cloud: '/assets/personal_design/img/jetsu_2.svg',
        logo_auth: '/assets/personal_design/img/jetsu_1.svg',
        custom_css: '/assets/personal_design/css/jetsu.css',
        title: 'Инфосистемы Джет',
        favicon: '/assets/personal_design/img/jetsu_favicon.svg?v=2',
    },
    'nekta.granatstan.ru': {
        logo_cloud: '/assets/personal_design/img/mnkt_logo.svg',
        logo_cloud_en: '/assets/personal_design/img/mnkt_logo.svg',
        logo_auth: '/assets/personal_design/img/mnkt_auth.png',
        logo_auth_en: '/assets/personal_design/img/mnkt_auth.png',
        custom_css: '/assets/personal_design/css/mnkt.css',
        title: 'МНКТ',
        favicon: '/assets/personal_design/img/mnkt_favicon.png?v=2',
    },
    'beliot.by': {
        logo_cloud: '/assets/personal_design/img/tvk_2.png',
        logo_auth: '/assets/personal_design/img/tvk_1.png',
        custom_css: '/assets/personal_design/css/tvk.css',
        title: 'Система диспетчеризации “BELIOT”',
        favicon: '/assets/personal_design/img/tvk_favicon-v3.png?v=3',
    },
    'askue.evo73.ru': {
        logo_cloud: '/assets/personal_design/img/evo73_2.png',
        logo_auth: '/assets/personal_design/img/evo73_1.png',
        custom_css: '/assets/personal_design/css/evo73.css',
        title: 'Телеком.ру',
        favicon: '/assets/personal_design/img/evo73_favicon.ico?v=2',
    },
    'exampl.ru': {
        // logo_cloud: '/assets/personal_design/img/evo73_2.png',
        // logo_auth: '/assets/personal_design/img/evo73_1.png',
        custom_css: '/assets/personal_design/css/exampl.css',
        title: 'ТЕСТ'
    },
    'ecsi.webd-gammi.ru': {
        logo_cloud: '/assets/personal_design/img/gammi_2.png',
        logo_auth: '/assets/personal_design/img/gammi_1.png',
        custom_css: '/assets/personal_design/css/gammi.css',
        title: 'ЕЦСИ',
        favicon: '/assets/personal_design/img/fav-gammi.svg?v=2',
    },
    'show.rvts.cloud': {
        logo_cloud: '/assets/personal_design/img/rvts_tech_1.svg',
        logo_auth: '/assets/personal_design/img/rvts_tech_2.svg',
        custom_css: '/assets/personal_design/css/rvts_tech.css',
        title: 'РВТС'
    },
    'es.primorsky.ru': {
        logo_cloud: '/assets/personal_design/img/rvts_tech_1.svg',
        logo_auth: '/assets/personal_design/img/rvts_tech_2.svg',
        custom_css: '/assets/personal_design/css/rvts_tech.css',
        title: 'РВТС'
    },
    'aurora-smart.com': {
        logo_cloud: '/assets/personal_design/img/aurora-smart_1.svg',
        logo_auth: '/assets/personal_design/img/aurora-smart_2.svg',
        custom_css: '/assets/personal_design/css/aurora-smart.css',
        title: 'Аврора-Смарт',
        favicon: '/assets/personal_design/img/auror-svg.svg?v=2',
    },
    'a365.tech': {
        logo_cloud: '/assets/personal_design/img/atech_2.png',
        logo_auth: '/assets/personal_design/img/atech_1.png',
        custom_css: '/assets/personal_design/css/atech.css',
        title: 'Аура 365',
        favicon: '/assets/personal_design/img/fav-atech.png?v=2',
    },
    'lorawan.mp-ges.ru': {
        logo_cloud: '/assets/personal_design/img/logo-spbzip-1.png',
        logo_auth: '/assets/personal_design/img/logo-spbzip-2.png',
        custom_css: '/assets/personal_design/css/spbzip.css',
        title: 'ООО «ГЭС»'
    },
    'service.vodomer.su': {
        logo_cloud: '/assets/personal_design/img/logo-vodomer-1.png',
        logo_cloud_en: '/assets/personal_design/img/logo-vodomer-1.png',
        logo_auth: '/assets/personal_design/img/logo-vodomer-2.png',
        logo_auth_en: '/assets/personal_design/img/logo-vodomer-2.png',
        custom_css: '/assets/personal_design/css/vodomer.css',
        title: 'ВОДОМЕР.СЕРВИС',
        favicon:  '/assets/personal_design/img/fav-vodomer.png?v=2',
    },
    'us.air-bit.ru': {
        logo_cloud: '/assets/personal_design/img/logo-air-1.svg',
        logo_auth: '/assets/personal_design/img/logo-air-2.svg',
        custom_css: '/assets/personal_design/css/air-bit-ru.css',
        title: 'АирБит (Учет ресурсов AirBit)',
        favicon: '/assets/personal_design/img/favicon-air.png?v=2',
    },
    'us.air-bit.eu': {
        logo_cloud: '/assets/personal_design/img/logo-air-1.svg',
        logo_auth: '/assets/personal_design/img/logo-air-2.svg',
        custom_css: '/assets/personal_design/css/air-bit-eu.css',
        title: 'AirBit (AirBit Resource Accounting)',
        favicon: '/assets/personal_design/img/favicon-air.png?v=2',
    },
    'pu.uk-gf.ru': {
        logo_cloud: '/assets/personal_design/img/uk-gf-logo-top.svg',
        logo_auth: '/assets/personal_design/img/uk-gf.svg',
        custom_css: '/assets/personal_design/css/uk-gf.css',
        title: 'ЕЦСИ',
        favicon: '/assets/personal_design/img/uk-gf-favicon.png?v=2',
    },
    'metering.beeline.kz': {
        logo_cloud: '/assets/personal_design/img/Beeline_Business_logo_150x20.svg',
        logo_cloud_en: '/assets/personal_design/img/Beeline_Business_logo_150x20.svg',
        logo_auth: '/assets/personal_design/img/Beeline_Business_logo_2_colours_330x95.png',
        logo_auth_en: '/assets/personal_design/img/Beeline_Business_logo_2_colours_330x95.png',
        custom_css: '/assets/personal_design/css/beeline.css',
        title: 'Beeline Business',
        favicon: '/assets/personal_design/img/Beeline_Business_logo_2.png',
    },
    'cloud.ptavt.ru': {
        logo_cloud: '/assets/personal_design/img/ptavt_logo_background.png',
        logo_auth: '/assets/personal_design/img/ptavt_logo_background.png',
        custom_css: '/assets/personal_design/css/cloud-ptavt.css',
        title: 'ПромТехАвтоматизация',
        favicon: '/assets/personal_design/img/ptavt-favico.png',
    },
    'aiis.ertelecom.ru': {
        logo_cloud: '/assets/personal_design/img/aiis_top_logo_RU.png',
        logo_auth: '/assets/personal_design/img/aiis_auth_logo_RU.png',
        title: 'АИИС ЭР-Телеком',
        logo_cloud_en: '/assets/personal_design/img/aiis_top_logo_EN.png',
        logo_auth_en : '/assets/personal_design/img/aiis_auth_logo_EN.png',
        title_en: 'AIIS ER-Telecom',
        custom_css: '/assets/personal_design/css/aiis.css',
        favicon: '/assets/personal_design/img/aiis_favicon.png',
    },
    'askuv.simdev.ru': {
        logo_cloud: '/assets/personal_design/img/simdev-logo.png',
        logo_auth: '/assets/personal_design/img/simdev-auth-logo.png',
        custom_css: '/assets/personal_design/css/simdev.css',
        title: 'SimDev',
        favicon: '/assets/personal_design/img/simdev-favico.png',
    },
    'saklau.betar.ru': {
        logo_cloud: '/assets/personal_design/img/saklau_betar_ru-logo.png',
        logo_auth: '/assets/personal_design/img/saklau_betar_ru-auth-logo.png',
        custom_css: '/assets/personal_design/css/saklau_betar_ru.css',
        title: 'БЕТАР / САКЛАУ / Умные приборы учета',
        favicon: '/assets/personal_design/img/saklau_betar_ru-favicon.png',
    },
    'telemeta.ru': {
        logo_cloud: '/assets/personal_design/img/telemeta_logo.svg',
        logo_auth: '/assets/personal_design/img/telemeta_auth_logo.svg',
        custom_css: '/assets/personal_design/css/telemeta.css',
        title: 'Telemeta',
        favicon: '/assets/personal_design/img/telemeta_favicon.png?v=1',
    },
    'app.kt-iot.kz': {
        favicon: '/assets/personal_design/img/mnkt_favicon.png?v=2',
    },
    'rvts.cloud': {
        logo_cloud: '/assets/personal_design/img/rvts_tech_1.svg',
        logo_auth: '/assets/personal_design/img/rvts_tech_2.svg',
        custom_css: '/assets/personal_design/css/rvts_tech.css',
        title: 'РВТС'
    },
    'rvts.tech': {
        favicon: '/assets/personal_design/img/rvts_tech.png?v=2',
    },
    'iotek.tech': {
        logo_cloud: '/assets/personal_design/img/iotek_tech_logo.png',
        logo_auth: '/assets/personal_design/img/iotek_tech_auth_logo.png',
        custom_css: '/assets/personal_design/css/iotek-tech.css',
        title: 'IOTEK платформа дистанционного учета ресурсов',
        favicon: '/assets/personal_design/img/iotek_tech_favicon.png?v=1',
    },
    'iot.a1.by': {
        logo_cloud: '/assets/personal_design/img/150x20px_A1_logo_negative_header.svg',
        logo_auth: '/assets/personal_design/img/iot_a1_by_auth_logo.svg',
        custom_css: '/assets/personal_design/css/iot-a1-by.css',
        title: 'А1 Интеллектуальный Мониторинг',
        favicon: '/assets/personal_design/img/iot_a1_by_favicon.png',
    },
    '213.184.227.169': {
        logo_cloud: '/assets/personal_design/img/belzenner_logo.png',
        logo_auth: '/assets/personal_design/img/belzenner_logo_auth.png',
        custom_css: '/assets/personal_design/css/belzenner.css',
        title: 'СООО "БелЦЕННЕР"',
        favicon: '/assets/personal_design/img/belzenner_favicon.png',
        linkToHelp: 'http://belzenner.by/',
    },
    'totiot.mn': {
        logo_cloud: '/assets/personal_design/img/tot_iot_white_logo.svg',
        logo_cloud_en: '/assets/personal_design/img/tot_iot_white_logo.svg',
        logo_auth: '/assets/personal_design/img/tot_iot_grey_logo.svg',
        logo_auth_en: '/assets/personal_design/img/tot_iot_grey_logo.svg',
        custom_css: '/assets/personal_design/css/totiot.css',
        title: 'TOT IOT',
        favicon: '/assets/personal_design/img/totiot_favicon.png',
    }
};
