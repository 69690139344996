import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { CloudService, DateManipulations } from './cloud.service';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import 'moment-timezone';
import { MatDialog } from '@angular/material/dialog';
import { PopupComponent } from 'src/app/shared/popup/popup.component';
import io from 'socket.io-client';
import { environment } from 'src/environments/environment';
import { forkJoin, ReplaySubject } from 'rxjs';
import { animate, state, style, transition, trigger } from '@angular/animations';
import * as _ from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as sharedActions from '../store/actions/shared.actions';
import { ObjectsService } from './objects/objects.service';
import { PassportService } from '@auth/passport.service';
import { TranslateLanguageService } from '@core/services/translate-language.service';
import { APP_DEFAULT_TIME_ZONE, APP_HEADER_LOGO_SRC, APP_TITLE } from '@core/constants/app.cloud.constants';
import { DateService } from '@core/services/date.service';
import { CheckAccessService } from '@core/services/check-access.service';
import { UsersApiService } from '@core/services/api/users-api.service';
import { EventsApiService } from '@core/services/api/events-api.service';
import { SnackbarService } from '@core/services/snackbar.service';
import { CompaniesApiService } from '@core/services/api/companies-api.service';
import pj from '../../../package.json';

@Component({
    selector: 'app-cloud',
    templateUrl: './cloud.component.html',
    styleUrls: ['./cloud.component.less'],
    animations: [
        trigger('openClose', [
            state(
                'open',
                style({
                    width: '250px'
                })
            ),
            state(
                'closed',
                style({
                    width: '70px'
                })
            ),
            transition('open => closed', [animate('.2s')]),
            transition('closed => open', [animate('.2s')])
        ])
    ]
})
export class CloudComponent implements OnInit, OnDestroy {
    public window = window;
    public title = '';
    public occurredEventsNotReadCount = 0;
    public isSpinnerVisible = true;
    public app: any;
    public isOpenedPopup = false;
    public settings: any;
    public occurredEvents: any = [];
    public occurredText: any = [];
    public menu = [];
    public environment = environment;
    public lang: any;
    public licenseInfo = null;
    public isReadAllowed = false;
    private servicesList: any;
    private occurredEventsApiCall$: any;

    private readonly destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

    constructor(
        private router: Router,
        private translate: TranslateService,
        private usersApiService: UsersApiService,
        private _cloudService: CloudService,
        private companiesApiService: CompaniesApiService,
        private dialog: MatDialog,
        private eventsApiService: EventsApiService,
        private auth: PassportService,
        private dateManipulations: DateManipulations,
        private snackbar: SnackbarService,
        private translateLanguageService: TranslateLanguageService,
        private store: Store,
        private objectsService: ObjectsService,
        private checkAccessService: CheckAccessService,
        private dateService: DateService
    ) {
        this._cloudService.appConst.pipe(takeUntil(this.destroyed$)).subscribe((app: any) => {
            this.app = app;
            this.dateService.timeZone = this.app?.currentTimeZone ?? this.app?.user?.user_time_zone ?? APP_DEFAULT_TIME_ZONE;
            this.dateService.companyTimeZone = this.app?.current_company?.time_zone ?? APP_DEFAULT_TIME_ZONE;
            this.menu = [];
            let locale = 'ru';
            const language = localStorage.getItem('language');
            if (translateLanguageService.availableLanguages.includes(language)) {
                locale = language;
            }
            this.translateLanguageService.updateCurrentLanguage(locale, false, true);
            if (app.access.access_group !== null) {
                app.access.access_group.access_rules.read.forEach((readId) => {
                    app.access.active_modules.forEach((module) => {
                        if (readId === module.id) {
                            if (!app.current_company.is_dealer && module.id === 2) {
                            } else {
                                this.menu.push(module);
                            }
                        }
                    });
                });
                if (this.menu.findIndex((item) => item.id === 3) !== -1) {
                    this.menu.splice(
                        this.menu.findIndex((item) => item.id === 3),
                        1
                    );
                }
                if (this.menu.findIndex((item) => item.id === 9) !== -1) {
                    this.menu.splice(
                        this.menu.findIndex((item) => item.id === 9),
                        1
                    );
                }
            } else if (this.checkAccessService.isAdmin()) {
                // EXPLAIN непонятный волшебный id
                if (this.environment.buildType === 'server' && app.current_company.id === 1 && this.checkAccessService.isAdmin()) {
                    this.auth
                        .getLicenseInfo()
                        .pipe(takeUntil(this.destroyed$))
                        .subscribe((licenseInfo) => {
                            this.licenseInfo = licenseInfo;
                            this.licenseInfo['license_expiry'] = moment
                                .unix(this.dateManipulations.dateLocal(this.licenseInfo?.license_expiry))
                                .utc()
                                .format('DD.MM.YYYY');
                        });
                }
                // EXPLAIN еще менее ясная система фильтрации
                this.menu = _.cloneDeep(app.access.active_modules);
                if (this.menu.findIndex((item) => item.id === 3) !== -1) {
                    this.menu.splice(
                        this.menu.findIndex((item) => item.id === 3),
                        1
                    );
                }
                if (this.menu.findIndex((item) => item.id === 9) !== -1) {
                    this.menu.splice(
                        this.menu.findIndex((item) => item.id === 9),
                        1
                    );
                }
                if (!app.current_company.is_dealer) {
                    this.menu.splice(
                        this.menu.findIndex((item) => item.id === 2),
                        1
                    );
                }
            }
            this.menu = _.sortBy(this.menu, ['sort']);
            this.menu = _.uniq(this.menu);
        });

        this.router.events.pipe(takeUntil(this.destroyed$)).subscribe(
            (event) => {
                if (event instanceof NavigationStart) {
                    this.isSpinnerVisible = true;
                } else if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
                    setTimeout(() => (this.isSpinnerVisible = false), 100);
                }
            },
            () => {
                setTimeout(() => (this.isSpinnerVisible = false), 100);
            }
        );

        this._cloudService.settings.pipe(takeUntil(this.destroyed$)).subscribe((settings: any) => {
            this.settings = settings;
        });
    }

    private _goToBack = false;

    get goToBack() {
        return this._goToBack;
    }

    set goToBack(value: boolean) {
        setTimeout(() => {
            this._goToBack = value;
        }, 300);
    }

    get fromAdmin() {
        return this.auth.fromAdmin;
    }

    ngOnInit() {
        this.occurredEventsApiCall$ = this.eventsApiService.getOccurredEventsList({
            paginate: false,
            per_page: 100,
            sort: 'desc',
            sort_field: 'created_at'
        });
        this.isReadAllowed = this.checkAccessService.canRead(8);
        this.translateLanguageService.currentLanguage$.pipe(takeUntil(this.destroyed$)).subscribe((lang) => {
            this.lang = lang;
            this.translateLanguageService.useLanguage(lang);
        });
        this.dialog.closeAll();
        this.servicesList = this.isReadAllowed
            ? [this.usersApiService.getUserInfo_users(), this.occurredEventsApiCall$]
            : [this.usersApiService.getUserInfo_users()];

        forkJoin(this.servicesList)
            .pipe(takeUntil(this.destroyed$))
            .subscribe((response: any) => {
                if (response[0]?.first_login === true && localStorage.getItem('wasLogin') !== 'was') {
                    const title = this.getPageTitle();
                    this.dialog
                        .open(PopupComponent, {
                            disableClose: true,
                            width: '400px',
                            data: {
                                type: 'subscribe newsletter',
                                name: this.translate.instant('cloud.msg_1'),
                                message: this.translate.instant('cloud.msg_2', { title })
                            }
                        })
                        .afterClosed()
                        .pipe(takeUntil(this.destroyed$))
                        .subscribe((data) => {
                            if (data && data.subscribeNewsletter) {
                                this.onSubscribeNewsletter();
                            }
                        });
                }
                localStorage.setItem('wasLogin', 'was');

                if (this.isReadAllowed) {
                    this.handleEventResponse(response[1]);
                }

                setTimeout(() => {
                    const socket_io = io(environment.socketUrl);
                    socket_io.on('connect', () => {
                        console.log('connected');
                        socket_io.emit('room', response[0].current_company_id);
                        if (this.isReadAllowed) {
                            this.handleSocketEvent(socket_io);
                        }
                    });
                }, 2000);
            });

        this._cloudService.loggedInSuccess$.pipe(takeUntil(this.destroyed$)).subscribe((res) => {
            if (res) {
                if (this.app.current_company && this.app.current_company.expires_at) {
                    const diff = this.getDaysToExpiredDate();
                    if (diff <= 5) {
                        this.dialog.open(PopupComponent, {
                            width: '400px',
                            data: {
                                type: 'block_notification',
                                name: this.translate.instant('cloud.msg_3'),
                                day: diff
                            }
                        });
                    }
                }
            }
        });

        this._cloudService.fillStore();
    }

    ngOnDestroy(): void {
        this.isSpinnerVisible = false;
        this.destroyed$.next(null);
        this.destroyed$.complete();
    }

    getAppVersion() {
        return pj.version;
    }

    getPageTitle(): string {
        const design = this._cloudService.personalDesign;
        return design?.title ?? APP_TITLE;
    }

    getHeaderIcon(slug: string): string {
        switch (slug) {
            case 'companies':
                return 'business';
            case 'access_rules':
                return 'build';
            case 'users':
                return 'account_circle';
            case 'devices':
                return 'devices';
            case 'events':
                return 'event_available';
            case 'objects':
                return 'list';
            case 'dashboard':
                return 'dashboard';
            case 'reports':
                return 'content_paste';
            case 'directories':
                return 'library_books';
            case 'objects_map':
                return 'map';
            default:
                return 'library_books';
        }
    }

    getEventValues(event) {
        return {
            value_1: event?.event !== null ? '"' + event?.event?.title + '"' : '',
            value_2: event?.device?.name ? event?.device?.name : ''
        };
    }

    isServerBuild() {
        return this.environment.buildType === 'server';
    }

    isNektaAdmin() {
        return this.checkAccessService.isNektaUser();
    }

    isAdmin() {
        return this.checkAccessService.isAdmin();
    }

    isDemo() {
        return this.app?.current_company?.is_demo;
    }

    onSubscribeNewsletter() {
        // TODO: check need or not
        // EXPLAIN
        console.log('onSubscribeNewsletter');
    }

    playAudioNotification() {
        const audio = new Audio();
        audio.src = '../../assets/audio/Уведомление.wav';
        audio.load();
        audio.play().then();
    }

    getLogoSrc(): string {
        const design = this._cloudService.personalDesign;
        if (!design) {
            return APP_HEADER_LOGO_SRC;
        } else {
            return this.lang === 'ru'
                ? design?.logo_cloud ?? design?.logo_cloud_en ?? APP_HEADER_LOGO_SRC
                : design?.logo_cloud_en ?? APP_HEADER_LOGO_SRC;
        }
    }

    getHelpIcon() {
        const design = this._cloudService.personalDesign?.linkToHelp;
        return !!design ? 'home' : 'help';
    }

    handleEventResponse(response) {
        this.occurredEvents = response;
        this.occurredEventsNotReadCount = response.filter((el) => el.status === 1).length;
        this.occurredText = response.slice(0, 4);
    }

    handleSocketEvent(socket_io) {
        socket_io.on('device_event', (message) => {
            console.log('message', message);
            this.snackbar.open(message.msg, false, 'event-device');
            this.occurredEventsApiCall$.pipe(takeUntil(this.destroyed$)).subscribe((list: any) => {
                this.occurredEvents = list;
                this.occurredEventsNotReadCount = list.filter((el) => el.status === 1).length;
                this.occurredText = list.slice(0, 4);
                if (message.event?.options?.sound_alert) {
                    this.playAudioNotification();
                }
            });
        });
    }

    exit(param) {
        this.isOpenedPopup = false;
        const body = {
            exit_point: param
        };
        this.companiesApiService.exitFromCompany(body).subscribe(() => {
            location.replace('/#/cloud/companies');
            location.reload();
        });
    }

    switchLang(param) {
        this.translateLanguageService.updateCurrentLanguage(param);
    }

    isOldTokenInLs() {
        return !!localStorage.getItem('access_token_old');
    }

    exitInAdmin() {
        this.isOpenedPopup = false;
        localStorage.setItem('access_token', localStorage.getItem('access_token_old'));
        localStorage.removeItem('access_token_old');
        setTimeout(() => {
            location.replace('/#/cloud/administration');
            location.reload();
        }, 200);
    }

    setAllOccurredRead() {
        this.eventsApiService
            .setOccurredEventsRead()
            .pipe(takeUntil(this.destroyed$))
            .subscribe((data) => {
                this.occurredEvents.forEach((event) => {
                    event.status = 2;
                });
                this.occurredEventsNotReadCount = 0;
                this.snackbar.open(data.msg);
            });
    }

    getFormattedDate(value) {
        return this.dateService.getFormattedDate(value);
    }

    setOccurredEventRead(event) {
        if (event.status !== 2) {
            this.eventsApiService
                .setOneOccurredEventsRead(event.id)
                .pipe(takeUntil(this.destroyed$))
                .subscribe((response: any) => {
                    this.occurredText.forEach((eventData) => {
                        if (eventData.id === response.id) {
                            eventData.status = 2;
                        }
                    });
                    this.occurredEventsNotReadCount -= 1;
                });
        }
    }

    goToHelp() {
        const personalDesignLink = this._cloudService.personalDesign?.linkToHelp;
        const link = this.lang === 'ru' ? 'http://help.nekta.tech/' : 'http://docs.nekta.tech/';
        window.open(personalDesignLink || link, '_blank');
    }

    onAuxMenuClick(event, url) {
        event.preventDefault();
        if (+event.which === 2) {
            window.open(`/#/cloud/${url}`, '_blank');
        }
    }

    infoExpiredDate() {
        if (!this.app.current_company.expires_at) {
            return false;
        }
        return this.getDaysToExpiredDate() < 5;
    }

    warningExpiredDate() {
        if (!this.app.current_company.expires_at) {
            return false;
        }
        return this.getDaysToExpiredDate() < 2;
    }

    getDaysToExpiredDate() {
        if (!this.app.current_company.expires_at) {
            return -1;
        }
        return moment
            .unix(this.app.current_company?.expires_at + this.app.user?.current_time_zone * 3600)
            .locale('ru')
            .diff(moment(), 'days');
    }

    onOpenNewTab(event, url) {
        event.preventDefault();
        if (event.which === 2) {
            window.open(`/#/cloud/${url}`, '_blank');
        }
    }

    togglePopup() {
        this.isOpenedPopup = !this.isOpenedPopup;
    }

    logout() {
        this.store.dispatch(new sharedActions.cleanStructureTree());
        this.objectsService.cleanStructureTree();
        this.router.navigate(['auth/logout']).then();
    }

    closePopup() {
        this.isOpenedPopup = false;
    }
}
