import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {CloudComponent} from 'src/app/cloud/cloud.component';
import {Observable} from 'rxjs';
import {DevicesService} from '../../../../services/devices.service';
import * as moment from 'moment';
import 'moment-timezone';
import * as echarts from 'echarts';
import {CheckAccess, CloudService, DateManipulations} from 'src/app/cloud/cloud.service';
import 'zrender/lib/svg/svg';
import {DataGatewaysComponent} from '../data.component';
import {ErrorsService} from '@core/services/errors.service';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {select, Store} from '@ngrx/store';
import {selectDeviceFieldTitlesState} from '../../../../../../store/selectors/selectors';
import {DateService} from '@core/services/date.service';
import {FileService, XLS_DEFAULT_STYLE, XLSFileData} from '@core/services/file.service';

@Component({
    selector: 'gateways-data',
    templateUrl: './data.component.html',
    styleUrls: ['./data.component.less']
})
export class GatewaysDataComponent implements OnInit, OnDestroy {
    subscribers = [];
    app;
    loadApp = this._cloudService.appConst.subscribe((app: any) => {
        this.app = app;
    });

    @Input() device: any;
    @Input() dateForm: any;
    @Input() msgGroup: any;
    isSpinnerVisible = true;

    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    dataSource = new MatTableDataSource();

    displayedColumns: string[];
    fieldsTitles: any[] = [];

    constructor(
        private store: Store<{}>,
        private translate: TranslateService,
        public cloud: CloudComponent,
        private devicesService: DevicesService,
        private _cloudService: CloudService,
        public _checkAccess: CheckAccess,
        private errors: ErrorsService,
        public dateManipulations: DateManipulations,
        private readonly dateService: DateService,
        private readonly fileService: FileService,
    ) {}

    ngOnInit() {
        this.subscribers[this.subscribers.length] = this.devicesService.refreshData.subscribe((value) => {
            this.isSpinnerVisible = true;
            setTimeout((_) => {
                this.store
                    .pipe(select(selectDeviceFieldTitlesState)).subscribe((res) => {
                    res.filter((el: any) => el.id === this.device.device_fields_titles.find((elem) => elem.id === el.id)?.id).
                    forEach((field: any) => this.fieldsTitles[field.name] = field); });
                this.subscribers[this.subscribers.length] = this.getMessages().subscribe((_) => {
                    this.dataSource.sort = this.sort;
                    this.dataSource.paginator = this.paginator;
                    this.isSpinnerVisible = false;
                    setTimeout((_) => {}, 500);
                });
            }, 500);
        });
        this.store
            .pipe(select(selectDeviceFieldTitlesState)).subscribe((res) => {
            res.filter((el: any) => el.id === this.device.device_fields_titles.find((elem) => elem.id === el.id)?.id).
            forEach((field: any) => this.fieldsTitles[field.name] = field); });
    }

    getMessages() {
        return new Observable<any>((observer) => {
            this.subscribers[this.subscribers.length] = this.devicesService
                .getMessages(this.device.id, 1, this.dateForm.startDate, this.dateForm.stopDate, this.msgGroup, 10000)
                .subscribe(
                    (messages) => {
                        if (messages.data.length == 0 || messages.fields_headers.length == 0) {
                            this.dataSource.data = [];
                            this.isSpinnerVisible = false;
                            return;
                        }
                        this.dataSource.data = messages.data;
                        this.displayedColumns = this.sortFields(messages.fields_headers);
                        observer.next(null);
                        observer.complete();
                    },
                    (error) => {
                        throw error;
                    }
                );
        });
    }

    sortFields(columns) {
        const mainArr = [
            'datetime',
            'in1',
            'start_in1',
            'end_in1',
            'delta_in1',
            'in2',
            'start_in2',
            'end_in2',
            'delta_in2',
            'in3',
            'start_in3',
            'end_in3',
            'delta_in3',
            'in4',
            'start_in4',
            'end_in4',
            'delta_in4',
            'tariff1',
            'start_tariff1',
            'end_tariff1',
            'delta_tariff1',
            'tariff2',
            'start_tariff2',
            'end_tariff2',
            'delta_tariff2',
            'tariff3',
            'start_tariff3',
            'end_tariff3',
            'delta_tariff3',
            'tariff4',
            'start_tariff4',
            'end_tariff4',
            'delta_tariff4',
            'tariff5',
            'start_tariff5',
            'end_tariff5',
            'delta_tariff5'
        ];
        const result = mainArr.filter(function (field) {
            return columns.indexOf(field) !== -1;
        });
        return result.concat(columns.filter((el) => !result.includes(el)));
    }

    getFormatedDate(value) {
        return moment
            .unix(value + this.app.user.current_time_zone * 3600)
            .locale('ru')
            .format('DD.MM.YYYY HH:mm');
    }

    typeof(value) {
        return typeof value;
    }

    async prepareMessagesForChart(inputs, messages): Promise<any> {
        return new Promise<any>((resolve) => {
            try {
                const data = { series: [], labels: [], inputs: [] };
                for (const input of inputs) {
                    if (data.inputs.indexOf(input) === -1 && input.search(/date/i) === -1) {
                        if (this.fieldsTitles.hasOwnProperty(input)) {
                            data.inputs.push(this.fieldsTitles[input].title);
                        } else {
                            data.inputs.push(input);
                        }
                    }
                    let index;
                    index = data.series.push({
                        name: this.fieldsTitles[input] ? this.fieldsTitles[input].title : input,
                        data: [],
                        step: 'start',
                        type: 'line',
                        markPoint: {
                            data: [
                                { type: 'max', name: this.translate.instant('devices.data_show.max') },
                                { type: 'min', name: this.translate.instant('devices.data_show.min') }
                            ],
                            symbolSize: [100, 20],
                            symbol: 'rect',
                            symbolRotate: 'arrow'
                        }
                    });
                    for (const message of messages) {
                        if (input.search(/date/i) === -1) {
                            data.series[index - 1].data.push(parseFloat(message[input]).toFixed(3));
                        }
                    }
                }
                // }
                this.generateLabels(messages).then(
                    (labels: any) => {
                        data.labels = this.unique(labels);
                        resolve(data);
                    },
                    (error) => {
                        throw error;
                    }
                );
            } catch (e) {
                throw e;
            }
        });
    }

    async generateLabels(messages) {
        return new Promise((resolve) => {
            const labels = [];
            for (const message of messages) {
                labels.push(
                    moment.unix(this.dateManipulations.dateLocal(message['datetime'])).format('DD.MM.YYYY HH:mm:ss')
                );
            }
            resolve(labels);
        });
    }

    unique(arr) {
        const obj = {};
        for (let i = 0; i < arr.length; i++) {
            const str = arr[i];
            obj[str] = true;
        }
        return Object.keys(obj);
    }

    renderChart(chartData, id) {
        const dom = document.getElementById(id);
        const innerContent = <HTMLElement>document.querySelector('.innerContent');
        const myChart = echarts.init(
            dom,
            {},
            { renderer: 'canvas', height: 400, width: innerContent.clientWidth - 50 }
        );
        let end = 100;
        if (chartData.labels > 5000) {
            end = 30;
        } else {
            end = 100;
        }
        const option = {
            toolbox: {
                top: '10%',
                right: '1%',
                show: true,
                feature: {
                    magicType: {
                        type: ['line', 'bar'],
                        title: {
                            line: this.translate.instant('devices.data_show.msg_9'),
                            bar: this.translate.instant('devices.data_show.msg_10')
                        }
                    }
                }
            },
            tooltip: {
                trigger: 'axis',
                confine: true
            },
            legend: {
                data: chartData.inputs,
                type: 'scroll'
            },
            grid: {
                left: '2%',
                right: '5%',
                bottom: '12%',
                containLabel: true
            },
            dataZoom: [
                {
                    type: 'inside',
                    start: 0,
                    end: end
                },
                {
                    start: 0,
                    end: 100,
                    handleIcon:
                        'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
                    handleSize: '80%',
                    handleStyle: {
                        color: '#fff',
                        shadowBlur: 3,
                        shadowColor: 'rgba(0, 0, 0, 0.6)',
                        shadowOffsetX: 2,
                        shadowOffsetY: 2
                    }
                }
            ],
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: chartData.labels
            },
            yAxis: {
                type: 'value'
            },
            series: chartData.series
        };
        myChart.setOption(option as any, true);
    }

    exportExcel() {
        const xlsTableColumns = this.displayedColumns.map((column) => ({
                key: column,
                title: this.getHeaderName(column),
                width: 30
        }));
        const fileName = this.translate.instant('devices.data_show.msg_6', { deviceName: this.device?.name || 'deviceName' }) +
            ' ' +
            this.dateService.getDateAsFormatString(this.dateForm?.startDate, 'dd.MM.yyyy') +
            ' ' +
            this.translate.instant('devices.data.msg_4') +
            ' ' +
            this.dateService.getDateAsFormatString(this.dateForm?.stopDate, 'dd.MM.yyyy') +
            '.xlsx';
        const fullMessages = this.dataSource.data.map((w) => this.getOnlyNeededValuesForXls(this.displayedColumns, w));
        const xlsTableRows = fullMessages.map((d) => this.fileService.getRowData(d, xlsTableColumns));
        const fileData: XLSFileData = {
            fileName,
            sheetName: this.device?.name || 'data',
            columns: xlsTableColumns,
            rows: xlsTableRows,
            style: XLS_DEFAULT_STYLE
        };
        this.fileService.downloadAsXLS(fileData);
    }

    getHeaderName(column) {
        const field = this.fieldsTitles[column];
        return field ? field.title : column;
    }

    getFormattedDate(timestamp) {
        this.dateService.deviceTimeZone = this.device.deviceTimezone;
        return this.dateService.getDateAsFormatString(timestamp, 'dd.MM.yyyy, HH:mm', 'device');
    }

    ngOnDestroy(): void {
        this.subscribers.forEach((item) => {
            if (item !== undefined) {
                item.unsubscribe();
            }
        });
        if (this.loadApp !== undefined) {
            this.loadApp.unsubscribe();
        }
    }

    private getOnlyNeededValuesForXls(arr: string[], v: any) {
        const result: any = {};
        arr.forEach((w) => {
            result[w] = this.getExcelValue(w, v?.[w]);
        });
        return result;
    }

    private getExcelValue(key: string, v: any) {
        if (key.includes('datetime') || key.includes('created_at')) {
            return typeof v === 'string' ? v : this.getFormattedDate(v);
        }
        if (typeof v === 'number') {
            return parseFloat(v.toFixed(4));
        }
        if (typeof v === 'string') {
            return v;
        }
        if (typeof v === 'boolean') {
            return v ? 'true' : 'false';
        }
        return '-';
    }
}
