import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

import { TranslateLanguageService } from '../services/translate-language.service';

@Pipe({
    name: 'translateMsgTypePipe'
})
export class TranslateMsgTypePipe implements PipeTransform {

    constructor(private translateLanguageService: TranslateLanguageService) {
    }

    transform(msgTypeId: number, allMsgTypes: any[] , language: string, filterGroup: number[]): any {
        let value = _.findLast(allMsgTypes, { id: msgTypeId })?.title || msgTypeId;
        if (filterGroup.length && filterGroup[0] === 3) {
            if (msgTypeId === 1) {
                value = this.translateLanguageService.translateInstant(`balance_groups.${filterGroup[0]}.${msgTypeId}`);
            }
            if (msgTypeId === 101) {
                value = this.translateLanguageService.translateInstant(`balance_groups.${filterGroup[0]}.${msgTypeId}`);
            }
        } else {
            if (msgTypeId === 1) {
                value = this.translateLanguageService.translateInstant(`balance_groups.${msgTypeId}`);
            }
        }

        return value;
    }
}
