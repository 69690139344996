import {Action} from '@ngrx/store';

export enum SharedActions {
    FetchAccountingPoints = '[AccountingPoints] Fetch accounting points',
    FetchAccountingPointsSuccess = '[AccountingPoints] Fetch accounting points success',
    FetchStructureTreeObjects = '[StructureTree] Fetch structure tree objects',
    SortStructureTreeObjects = '[StructureTree] Sort structure tree objects',
    SortChildObjects = '[StructureTree] Sort child objects',
    FetchStructureTreeObjectsSuccess = '[StructureTree] Fetch structure tree objects success',
    FetchStructureTreeBalanceGroups = '[StructureTree] Fetch structure tree balance groups',
    FetchStructureTreeBalanceGroupsSuccess = '[StructureTree] Fetch structure tree balance groups success',
    FetchStructureTreeInfrastructureReports = '[StructureTree] Fetch structure tree infrastructure reports',
    FetchStructureTreeInfrastructureReportsSuccess = '[StructureTree] Fetch structure tree infrastructure reports success',
    FetchStructureTreeNewReportAccountingPoints = '[StructureTree] Fetch structure tree new report accounting points',
    FetchStructureTreeNewReportAccountingPointsSuccess = '[StructureTree] Fetch structure tree new report accounting points success',
    CleanStructureTree = '[StructureTree] Clean structure tree',
    CleanStructureTreeBalanceGroups = '[StructureTree] Clean structure tree balance groups',
    CleanStructureTreeNewReportAccountingPoints = '[StructureTree] Clean structure tree new report accounting points',
    AddElementToStructureTree = '[StructureTree] Add element to structure tree objects',
    CacheElementToTreeObjects = '[StructureTree] Cache element to tree objects',
    CacheElementToTreeBalanceGroups = '[StructureTree] Cache element to tree balance groups',
    CacheElementToTreeInfrastructureReports = '[StructureTree] Cache element to tree infrastructure reports',
    CacheElementToTreeNewReportAccountingPoints = '[StructureTree] Cache element to tree new report accounting points',
    EditElementInTreeObjects = '[StructureTree] Edit element in tree objects',
    DeleteElementInTreeObjects = '[StructureTree] Delete element in tree objects',
}

export class fetchAccountingPoints implements Action {
    readonly type = '[AccountingPoints] Fetch accounting points';

    constructor(public payload?: any) {
    }
}

export class fetchAccountingPointsSuccess implements Action {
    readonly type = '[AccountingPoints] Fetch accounting points success';

    constructor(public payload: any) {
    }
}

export class fetchStructureTreeObjects implements Action {
    readonly type = '[StructureTree] Fetch structure tree objects';

    constructor() {
    }
}
export class sortStructureTreeObjects implements Action {
    readonly type = '[StructureTree] Sort structure tree objects';

    constructor() {
    }
}

export class sortChildObjects implements Action {
    readonly type = '[StructureTree] Sort child objects';

    constructor(public payload: { parentId: string }) {}
}

export class fetchStructureTreeObjectsSuccess implements Action {
    readonly type = '[StructureTree] Fetch structure tree objects success';

    constructor(public payload: any) {
    }
}
export class fetchStructureTreeBalanceGroups implements Action {
    readonly type = '[StructureTree] Fetch structure tree balance groups';

    constructor() {
    }
}

export class fetchStructureTreeBalanceGroupsSuccess implements Action {
    readonly type = '[StructureTree] Fetch structure tree balance groups success';

    constructor(public payload: any) {
    }
}

export class fetchStructureTreeNewReportAccountingPoints implements Action {
    readonly type = '[StructureTree] Fetch structure tree new report accounting points';

    constructor() {
    }
}

export class fetchStructureTreeNewReportAccountingPointsSuccess implements Action {
    readonly type = '[StructureTree] Fetch structure tree new report accounting points success';

    constructor(public payload: any) {
    }
}

export class fetchStructureTreeInfrastructureReports implements Action {
    readonly type = '[StructureTree] Fetch structure tree infrastructure reports';

    constructor() {
    }
}

export class fetchStructureTreeInfrastructureReportsSuccess implements Action {
    readonly type = '[StructureTree] Fetch structure tree infrastructure reports success';

    constructor(public payload: any) {
    }
}

export class cleanStructureTree implements Action {
    readonly type = '[StructureTree] Clean structure tree';

    constructor() {
    }
}

export class cleanStructureTreeBalanceGroups implements Action {
    readonly type = '[StructureTree] Clean structure tree balance groups';

    constructor() {
    }
}

export class cleanStructureTreeNewReportAccountingPoints implements Action {
    readonly type = '[StructureTree] Clean structure tree new report accounting points';

    constructor() {
    }
}


export class addElementToStructureTree implements Action {
    readonly type = '[StructureTree] Add element to structure tree objects';

    constructor(public payload: any) {
    }
}

export class cacheElementToStructureTreeObjects implements Action {
    readonly type = '[StructureTree] Cache element to tree objects';

    constructor(public payload: any) {
    }
}

export class cacheElementToStructureTreeBalanceGroups implements Action {
    readonly type = '[StructureTree] Cache element to tree balance groups';

    constructor(public payload: any) {
    }
}

export class cacheElementToStructureTreeInfrastructureReports implements Action {
    readonly type = '[StructureTree] Cache element to tree infrastructure reports';

    constructor(public payload: any) {
    }
}

export class cacheElementToStructureTreeNewReportAccountingPoints implements Action {
    readonly type = '[StructureTree] Cache element to tree new report accounting points';

    constructor(public payload: any) {
    }
}

export class editElementInTreeObjects implements Action {
    readonly type = '[StructureTree] Edit element in tree objects';

    constructor(public payload: any) {
    }
}

export class deleteElementInTreeObjects implements Action {
    readonly type = '[StructureTree] Delete element in tree objects';

    constructor(public payload: any) {
    }
}





export type SharedUnion =
    | fetchAccountingPoints
    | fetchAccountingPointsSuccess
    | addElementToStructureTree
    | cleanStructureTree
    | editElementInTreeObjects
    | deleteElementInTreeObjects
    | fetchStructureTreeObjects
    | sortStructureTreeObjects
    | sortChildObjects
    | fetchStructureTreeObjectsSuccess
    | fetchStructureTreeBalanceGroupsSuccess
    | fetchStructureTreeBalanceGroups
    | fetchStructureTreeInfrastructureReports
    | fetchStructureTreeInfrastructureReportsSuccess
    | cacheElementToStructureTreeObjects
    | cacheElementToStructureTreeBalanceGroups
    | cacheElementToStructureTreeInfrastructureReports
    | cacheElementToStructureTreeNewReportAccountingPoints
    | fetchStructureTreeNewReportAccountingPoints
    | fetchStructureTreeNewReportAccountingPointsSuccess
    | cleanStructureTreeNewReportAccountingPoints
    | cleanStructureTreeBalanceGroups;
