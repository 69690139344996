<mat-card class="registration-card">
    <mat-card-header>
        <mat-card-title [translate]="'registrationForm.h1'"></mat-card-title>
        <mat-card-subtitle [translate]="'registrationForm.desc'"></mat-card-subtitle>
    </mat-card-header>
    <form [formGroup]="registrationForm" (ngSubmit)="signup()">
        <div class="registration-container">
            <mat-form-field floatLabel="auto" class="registration-input">
                <input
                    matInput
                    type="text"
                    [placeholder]="'registrationForm.name' | translate"
                    required
                    formControlName="name"
                    name="name"
                />
            </mat-form-field>
            <mat-form-field floatLabel="auto" class="registration-input">
                <input
                    matInput
                    type="email"
                    [placeholder]="'registrationForm.email' | translate"
                    required
                    formControlName="email"
                    name="email"
                />
                <mat-error *ngIf="registrationForm.controls['email'].hasError('email')">
                    {{ 'registrationForm.errors.fail_email' | translate }}
                </mat-error>
            </mat-form-field>

            <ng-container>
                <app-country-picker
                    [fieldRequired]="true"
                    (numberChanged)="onNumberChange($event)"
                ></app-country-picker>
                <mat-error
                    *ngIf="registrationForm.controls['phone_number'].hasError('required') || registrationForm.controls['phone_number'].hasError('minlength') || registrationForm.controls['phone_number'].hasError('maxlength')">
                    {{ 'registrationForm.errors.fail_phone_number' | translate }}
                </mat-error>
            </ng-container>

            <mat-form-field *ngIf="!environment.useRandomPasswords" floatLabel="auto" class="registration-input">
                <input
                    matInput
                    type="password"
                    [placeholder]="'registrationForm.password' | translate"
                    required
                    formControlName="password"
                    name="password"
                />
                <mat-error *ngIf="registrationForm.controls['password'].hasError('pattern')">
                    {{ 'registrationForm.errors.easy_password' | translate }}
                </mat-error>
            </mat-form-field>
            <mat-form-field *ngIf="!environment.useRandomPasswords" floatLabel="auto" class="registration-input">
                <input
                    matInput
                    type="password"
                    [placeholder]="'registrationForm.password_confirmation' | translate"
                    required
                    formControlName="password_confirmation"
                    name="password_confirmation"
                />
                <mat-error *ngIf="registrationForm.controls['password_confirmation'].hasError('minlength')">
                    {{ 'registrationForm.errors.fail_password' | translate }}
                </mat-error>
            </mat-form-field>
            <mat-form-field floatLabel="auto" class="registration-input">
                <input
                    matInput
                    type="text"
                    [placeholder]="'registrationForm.company_name' | translate"
                    required
                    formControlName="company_name"
                    name="company_name"
                />
            </mat-form-field>
        </div>
        <div class="registration-button-container">
            <button [disabled]="loading" id="registration-button" mat-button mat-flat-button color="primary">
                {{ 'registrationForm.button.registration' | translate }}
            </button>
        </div>
    </form>
    <div class="already-register">
        <button mat-button routerLink="/auth/login" id="alreadyreg-button">
            {{ 'registrationForm.button.login' | translate }}
        </button>
    </div>
</mat-card>
