<div class="header">
    <div class="header__container">
        <!--        Logo-->
        <img routerLink="/cloud"
             class="header__logo"
             [src]="getLogoSrc()"
             alt="logo"/>
        <div class="header__toolbar" #mainMenu>
            <!--        Navigation-->
            <ng-container *ngTemplateOutlet="navBlock"></ng-container>
            <div class="header__toolbar-buttons">
                <!-- Language -->
                <button class="header__buttons-language" [matMenuTriggerFor]="lang" mat-button>
                    <mat-icon class="header__buttons-icon">language</mat-icon>
                    <span class="header__list-span">{{ 'cloud.changeLang' | translate }}</span>
                </button>
                <mat-menu #lang="matMenu">
                    <button mat-menu-item (click)="switchLang('ru')" class="menu-btn-centered">
                        <img [alt]="'cloud.ru' | translate" src="../../assets/flags/4x3/ru.svg" width="32"/>
                        <span>{{ 'cloud.ru' | translate }}</span>
                    </button>
                    <button mat-menu-item (click)="switchLang('en')" class="menu-btn-centered">
                        <img [alt]="'cloud.ru' | translate" src="../../assets/flags/4x3/gb.svg" width="32"/>
                        <span>{{ 'cloud.en' | translate }}</span>
                    </button>
                    <button mat-menu-item (click)="switchLang('mn')" class="menu-btn-centered">
                        <img [alt]="'cloud.ru' | translate" src="../../assets/flags/4x3/mn.svg" width="32"/>
                        <span>{{ 'cloud.mn' | translate }}</span>
                    </button>
                </mat-menu>
                <!--                Help-->
                <button class="header__buttons-classic personal_design__menu-buttons"
                        mat-button (click)="goToHelp()">
                    <mat-icon class="material-icons">{{getHelpIcon()}}</mat-icon>
                </button>
                <!--                Events-->
                <ng-container *ngTemplateOutlet="eventBlock"></ng-container>
                <!--                User Menu-->
                <ng-container *ngTemplateOutlet="userMenuBlock"></ng-container>
            </div>
        </div>
        <button class="header__burger" aria-label="Main Menu"
                (click)="togglePopup()">
            <mat-icon>menu</mat-icon>
        </button>
    </div>
</div>
<div *ngIf="!isOpenedPopup; else mobileMenu;"
     id="main-content"
     class="main-cloud-content">
    <mat-progress-spinner *ngIf="isSpinnerVisible" color="primary" mode="indeterminate"></mat-progress-spinner>
    <router-outlet *ngIf="!isSpinnerVisible"></router-outlet>
</div>

<ng-template #mobileMenu>
    <div class="header__popup">
        <div class="header__toolbar" [class._isOpenedPopup]="isOpenedPopup">
            <div class="header__toolbar-buttons" [class._isOpenedPopup]="isOpenedPopup">
                <!--                User Menu-->
                <ng-container *ngTemplateOutlet="userMenuBlock"></ng-container>
                <!--                Events-->
                <ng-container *ngTemplateOutlet="eventBlock"></ng-container>
            </div>
            <!--        Navigation-->
            <ng-container *ngTemplateOutlet="navBlock"></ng-container>
        </div>
    </div>
</ng-template>

<!--        Navigation-->
<ng-template #navBlock>
    <mat-list class="header__toolbar-menu" [class._isOpenedPopup]="isOpenedPopup">
        <mat-list-item *ngFor="let module of menu" class="header__list">
            <button class="header__list-button"
                    mat-icon-button
                    routerLinkActive="active"
                    [routerLink]="module.slug"
                    (click)="closePopup()"
                    (mouseup)="onOpenNewTab($event, module.slug)"
                    (auxclick)="onAuxMenuClick($event, module.slug)">
                <mat-icon class="header__list-icon">
                    {{ getHeaderIcon(module.slug) }}
                </mat-icon>
                <span class="header__list-span">
                    {{ 'cloud.navButtons.' + module.slug | translate }}
                </span>
            </button>
        </mat-list-item>
    </mat-list>
</ng-template>
<!--                Events-->
<ng-template #eventBlock>
    <button class="header__buttons-classic h-100"
            [matMenuTriggerFor]="events"
            mat-button
            *ngIf="isReadAllowed">
        <mat-icon
            [matBadgeHidden]="occurredEventsNotReadCount<=0"
            matBadge="{{ occurredEventsNotReadCount }}"
            matBadgeColor="warn"
            matBadgeSize="small">
            notification_important
        </mat-icon>
    </button>
    <mat-menu class="header-notifications-menu"
              #events="matMenu"
              xPosition="before">
        <div *ngIf="occurredEvents.length > 0; else emptyEvents">
            <button mat-menu-item routerLink="events" class="menu-btn-centered">
                <div class="text-center w-100">
                    {{ 'notifications.title' | translate }}
                </div>
            </button>
            <div class="occurred-event-container p-2" *ngFor="let event of occurredText">
                <div class="occurred-event p-2" [class.occurred-event-shadowed]="event.status === 1">
                    <mat-icon class="mat-warn flex-fill">warning</mat-icon>
                    <a class="pl-2"
                       (click)="setOccurredEventRead(event)">
                        <b>{{ getFormattedDate(event?.created_at) }}</b>
                        <br/>
                        {{ 'notifications.info_row' | translate : getEventValues(event) }}
                    </a>
                </div>
            </div>
            <button class="w-100 p-2 bg-primary personal_design_button header-notifications-menu--read-all"
                    mat-button (click)="setAllOccurredRead()">
                {{ 'notifications.mark_all_as_read' | translate }}
            </button>
        </div>
        <ng-template #emptyEvents>
            <p class="ph-4">{{ 'cloud.no_events' | translate }}</p>
        </ng-template>
    </mat-menu>
</ng-template>

<!--                User Menu-->
<ng-template #userMenuBlock>
    <button class="header__buttons-logout header__buttons-classic"
            mat-button
            [matMenuTriggerFor]="menuExit">
        <div
            class="company-head"
            [class.company-head--info]="infoExpiredDate() || app?.current_company?.is_demo"
            [class.company-head--warning]="warningExpiredDate()"
        ></div>
        <mat-icon>account_circle</mat-icon>
        <span class="header__list-span company-name">
                        {{ app?.current_company?.name }}
        </span>
        <mat-icon>keyboard_arrow_down</mat-icon>
    </button>
    <mat-menu #menuExit="matMenu">
        <div class="company-user-info">
            <h2 class="mv-1">{{ app?.current_company?.name }}</h2>
            <p class="mv-1">{{ 'cloud.text.hello' | translate }}{{ app?.user?.name }}</p>
            <p>{{ 'cloud.text.current_time' | translate }}</p>
            <app-clock></app-clock>
        </div>
        <div
            class="company-user-info__license-info"
            *ngIf="licenseInfo && isServerBuild()"
        >
            <p class="mv-1">{{ 'cloud.text.license_info_date' | translate }} {{ licenseInfo?.license_expiry }}</p>
            <p class="mv-1">
                {{ 'cloud.text.license_info_current_devices' | translate }}
                {{ licenseInfo?.server_devices_count }}
            </p>
            <p class="mv-1">
                {{ 'cloud.text.license_info_max_devices' | translate }}
                {{ licenseInfo?.max_allowable_devices_count }}
            </p>
        </div>
        <div class="company-user-info__license-info"
             *ngIf="isServerBuild() && (app?.current_company?.expires_at || isDemo())">
            <p class="mv-1" *ngIf="app?.current_company?.expires_at">
                <strong>{{ 'companies.list.columns.expires' | translate }}: </strong>
                <span [class.warn-orange]="infoExpiredDate()"
                      [class.warn-red]="warningExpiredDate()">
                    {{ getFormattedDate(app?.current_company?.expires_at) }}
                </span>
            </p>
<!--            <p class="mv-1" *ngIf="isDemo()">-->
<!--                {{ 'cloud.demo' | translate }}<br/><br/>-->
<!--                <strong>{{ 'cloud.available_devices' | translate }}: </strong>-->
<!--            </p>-->
<!--            <div class="devices-bar-demo-limit mv-1" *ngIf="isDemo()">-->
<!--                <p>{{ app?.current_company?.devices_info?.total_count_devices || 0 }}</p>-->
<!--                <mat-progress-bar-->
<!--                    mode="determinate"-->
<!--                    [value]="(app?.current_company?.devices_info?.total_count_devices || 0) * 10"-->
<!--                ></mat-progress-bar>-->
<!--                <p>10</p>-->
<!--            </div>-->
        </div>
        <button
            mat-menu-item
            routerLink="administration"
            routerLinkActive="active"
            *ngIf="isNektaAdmin()">
            <mat-icon>chrome_reader_mode</mat-icon>
            <span>{{ 'cloud.navButtons.administration' | translate }}</span>
        </button>
        <button mat-menu-item routerLink="profile">
            <mat-icon>edit</mat-icon>
            <span>{{ 'cloud.navButtons.edit_profile' | translate }}</span>
        </button>
        <button *ngIf="isAdmin()" mat-menu-item routerLink="company-settings">
            <mat-icon>build</mat-icon>
            <span>{{ 'cloud.navButtons.company_settings' | translate }}</span>
        </button>
        <button
            mat-menu-item
            *ngIf="app.access['rules_belong_to_company_id']"
            (click)="exit('parent')"
        >
            <mat-icon>exit_to_app</mat-icon>
            <span>{{ 'cloud.backPrevious' | translate }}</span>
        </button>
        <button mat-menu-item (click)="exitInAdmin()" *ngIf="isOldTokenInLs()">
            <mat-icon>exit_to_app</mat-icon>
            <span>{{ 'cloud.backMain' | translate }}</span>
        </button>
        <button mat-menu-item (click)="logout()">
            <mat-icon>exit_to_app</mat-icon>
            <span>{{ 'cloud.exitProfile' | translate }}</span>
        </button>
        <p class="header-program-version">
            {{ 'cloud.software_version' | translate }}{{ getAppVersion() }}
        </p>
    </mat-menu>
</ng-template>
