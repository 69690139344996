import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PassportService } from 'src/app/auth/passport.service';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ObjectsApiService {
    private _baseURL: string;

    constructor(private _http: HttpClient, private auth: PassportService) {
        this._baseURL = environment.baseURL;
    }

    addNewElement(body) {
        return this._http.post(this._baseURL + 'objects/add', body, { headers: this.getHeaders() });
    }

    getStructureTree(isMap?) {
        return this._http
            .get(this._baseURL + `objects/${isMap ? 'map' : 'tree'}`, { headers: this.getHeaders() })
            .pipe(map((result: any) => (isMap ? result.data : result.data.tree)));
    }

    getBuildStructureTree(body: any = {}) {
        return this._http.post<any>(this._baseURL + `objects/build_tree`, body, { headers: this.getHeaders() });
    }

    linkWithParentChildes(body: {object_ids: any}) {
        return this._http.post<any>(this._baseURL + `objects/link_with_parents_childs`, body, { headers: this.getHeaders() });
    }

    saveDevicesForObject(body) {
        return this._http.post(this._baseURL + 'objects/tie_devices', body, { headers: this.getHeaders() });
    }

    saveGatewaysForObject(body) {
        return this._http.post(this._baseURL + 'objects/tie_gateways', body, { headers: this.getHeaders() });
    }

    saveBaseStationsForObject(body) {
        return this._http.post(this._baseURL + 'objects/tie_base_stations', body, { headers: this.getHeaders() });
    }

    saveUsersForObject(body) {
        return this._http.post(this._baseURL + 'objects/tie_users', body, { headers: this.getHeaders() });
    }

    deleteObjectElement(body) {
        return this._http.delete(this._baseURL + 'objects/remove', { headers: this.getHeaders(), body });
    }

    updateSortElement(body) {
        return this._http.post(this._baseURL + 'objects/update_sort', body, { headers: this.getHeaders() });
    }

    updateElement(body) {
        return this._http.post(this._baseURL + 'objects/update', body, { headers: this.getHeaders() });
    }

    getAttributesList(body = {}) {
        return this._http.post(this._baseURL + 'objects/attributes', body, { headers: this.getHeaders() });
    }

    saveAttribute(body) {
        return this._http.post(this._baseURL + 'objects/attribute/set', body, { headers: this.getHeaders() });
    }

    getAccountingPoints(body) {
        return this._http.post(this._baseURL + 'objects/accounting_point/list', body, { headers: this.getHeaders() });
    }

    getAccountingPointListByIds(body) {
        return this._http.post(this._baseURL + 'objects/accounting_point/company/list', body, { headers: this.getHeaders() });
    }

    getNestedAccountingPoint(body) {
        return this._http
            .post(this._baseURL + 'objects/accounting_point/nested', body, { headers: this.getHeaders() })
            .pipe(map((res: any) => res.data.accounting_points));
    }

    addAccountingPoint(body) {
        return this._http.post(this._baseURL + 'objects/accounting_point/create', body, { headers: this.getHeaders() });
    }

    importAccountingPoints(body) {
        return this._http.post(this._baseURL + 'objects/accounting_point/import', body, { headers: this.getHeaders() });
    }

    editAccountingPoint(body) {
        return this._http.put(this._baseURL + `objects/accounting_point/update`, body, { headers: this.getHeaders() });
    }

    deleteAccountingPoint(body) {
        return this._http.delete(this._baseURL + 'objects/accounting_point/delete', { headers: this.getHeaders(), body });
    }

    deAttachDeviceAccountingPoint(body) {
        return this._http.put(this._baseURL + 'objects/accounting_point/untie_device', body, { headers: this.getHeaders() });
    }

    attachDevice(body) {
        return this._http.put(this._baseURL + 'objects/accounting_point/tie_device', body, { headers: this.getHeaders() });
    }

    changeAccountingPointDevice(body) {
        return this._http.put(this._baseURL + 'objects/accounting_point/replace_device', body, { headers: this.getHeaders() });
    }

    getAllAccountingPointList(body) {
        return this._http
            .post(this._baseURL + 'objects/accounting_point/company/list', body, { headers: this.getHeaders() })
            .pipe(map((res: any) => res.data.accounting_points));
    }

    getTabsByAccountingPointId(body) {
        return this._http
            .post(this._baseURL + 'objects/accounting_point/tabs', body, { headers: this.getHeaders() })
            .pipe(map((res: any) => res.data.tabs));
    }

    getTabsByDeviceGroupId(body) {
        return this._http
            .post(this._baseURL + 'device/model/tabs', body, { headers: this.getHeaders() })
            .pipe(map((res: any) => res.data.merged_tabs));
    }

    getAccountingPointById(id) {
        return this._http
            .get(this._baseURL + `objects/accounting_point/${id}`, { headers: this.getHeaders() })
            .pipe(map((res: any) => res.data.accounting_point));
    }

    getAccountingPointLogs(body) {
        return this._http
            .post(this._baseURL + `objects/accounting_point/logs/`, body, { headers: this.getHeaders() })
            .pipe(map((res: any) => res.data.accounting_point_logs));
    }

    untieAccountingPoint(body) {
        return this._http
            .post(this._baseURL + `objects/accounting_point/untie_object/`, body, { headers: this.getHeaders() })
            .pipe(map((res: any) => res.msg));
    }

    tieAccountingPoint(body) {
        return this._http
            .post(this._baseURL + `objects/accounting_point/tie_object/`, body, { headers: this.getHeaders() })
            .pipe(map((res: any) => res.msg));
    }

    // DELETE: already exist in referenceApi service
    // getAllTransformators() {
    //     return this._http
    //         .post(this._baseURL + `reference/transformer/list`, {}, { headers: this.getHeaders() });
    // }

    // TODO: this method DO NOT use baseURL
    getAddressByCoords(coords, lang = 'ru') {
        return this._http.get(
            `https://nominatim.openstreetmap.org/reverse?format=json&lat=${coords.lat}&lon=${coords.lng}&zoom=18&addressdetails=1&accept-language=${lang}`
        );
    }

    // TODO: this method DO NOT use baseURL
    getCoordinatesByAddress(address, lang = 'ru') {
        return this._http.get(
            `https://nominatim.openstreetmap.org/search.php?q=${address}&polygon_geojson=1&accept-language=ru&limit=1&format=jsonv2`
        );
    }

    getAccountingPointCompatibleType() {
        return this._http
            .get(this._baseURL + `objects/accounting_point/resource/types`, { headers: this.getHeaders() })
            .pipe(map((res: any) => res.data.resource_types));
    }

    getObject(body) {
        return this._http.post<any>(`${this._baseURL}objects/object/get`, body, { headers: this.getHeaders() });
    }

    getObjectsByIds(body) {
        return this._http.post<any>(`${this._baseURL}objects/objects/get`, body, { headers: this.getHeaders() });
    }

    getDadataInfoFlat(body) {
        return this._http.post<any>(`${this._baseURL}objects/get_info_from_dadata`, body, { headers: this.getHeaders() });
    }

    getHeatCalculatingByObjectId(id) {
        return this._http.post<any>(`${this._baseURL}objects/get_heat_calculation`, { object_id: id }, { headers: this.getHeaders() });
    }

    updateAccountingPointStandard(id, body) {
        return this._http.post<any>(`${this._baseURL}objects/accounting_point/update_standard/${id}`, body, {
            headers: this.getHeaders()
        });
    }

    getSearchValueGAR(body) {
        return this._http.post(this._baseURL + 'proxy/gar_hints', body, { headers: this.getHeaders() });
    }

    importObjectsFromGAR(body) {
        return this._http.post(this._baseURL + 'objects/add_automatically', body, { headers: this.getHeaders() });
    }

    reportInfrastructureCreate(body) {
        return this._http.post(this._baseURL + 'report/infrastructure/create', body, { headers: this.getHeaders() });
    }

    massTieDevicesToObjects(body) {
        return this._http.post(this._baseURL + 'objects/mass_tie_devices', body, { headers: this.getHeaders() });
    }

    getListOfFiles() {
        return this._http.get(this._baseURL + 'objects/smart_import/files', { headers: this.getHeaders() });
    }

    getDetailedLogs(id) {
        return this._http.get(this._baseURL + `objects/smart_import/file/${id}`, { headers: this.getHeaders() });
    }

    deleteLogs(id) {
        return this._http.delete(this._baseURL + `objects/smart_import/file/${id}`, { headers: this.getHeaders() });
    }

    private getHeaders() {
        return new HttpHeaders().append('Access-Control-Allow-Origin', '*').append('Authorization', 'Bearer ' + this.auth.access_token);
    }
}
