import { Directive, ElementRef, HostListener, Renderer2, Output, EventEmitter } from '@angular/core';

@Directive({
    selector: '[appResizable]'
})
export class ResizableDirective {
    @Output() widthChanged = new EventEmitter<number>();

    private resizing = false;
    private startX: number;
    private startWidth: number;

    constructor(private el: ElementRef, private renderer: Renderer2) {
        this.renderer.setStyle(this.el.nativeElement, 'position', 'relative');

        const handle = this.renderer.createElement('div');
        this.renderer.setStyle(handle, 'width', '5px');
        this.renderer.setStyle(handle, 'cursor', 'ew-resize');
        this.renderer.setStyle(handle, 'position', 'absolute');
        this.renderer.setStyle(handle, 'top', '0');
        this.renderer.setStyle(handle, 'right', '0');
        this.renderer.setStyle(handle, 'bottom', '0');
        this.renderer.setStyle(handle, 'z-index', '10');
        this.renderer.appendChild(this.el.nativeElement, handle);

        this.renderer.listen(handle, 'mousedown', this.onMouseDown.bind(this));
    }

    @HostListener('document:mousemove', ['$event'])
    onMouseMove(event: MouseEvent) {
        if (!this.resizing) return;
        const dx = event.clientX - this.startX;
        const newWidth = this.startWidth + dx;
        if (newWidth > 100) {
            this.renderer.setStyle(this.el.nativeElement, 'width', `${newWidth}px`);
            this.widthChanged.emit(newWidth);
        }
    }


    @HostListener('document:mouseup')
    onMouseUp() {
        this.resizing = false;
    }

    private onMouseDown(event: MouseEvent) {
        this.resizing = true;
        this.startX = event.clientX;
        const width = this.el.nativeElement.getBoundingClientRect().width;
        this.startWidth = width;
        event.preventDefault();
    }
}
