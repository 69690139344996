import { EnvironmentConfig } from 'src/environments/env.model';

export const environment: EnvironmentConfig = {
    production: true,
    baseURL: 'https://core.nekta.cloud/api/',
    socketUrl: 'https://nekta.cloud:3003',
    buildType: 'cloud',
    errorLogger: true,
    isKerberos: false,
    ldapSupport: false,
    useRandomPasswords: false,
};
