import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DevicesService } from '../../../services/devices.service';
import { ButtonsService, CheckAccess, CloudService, DateManipulations } from '../../../../cloud.service';
import 'moment-timezone';
import { ReplaySubject, Subject } from 'rxjs';
import { CloudComponent } from '../../../../cloud.component';
import { TranslateService } from '@ngx-translate/core';
import { select, Store } from '@ngrx/store';
import * as fromModels from '../../../../../store/actions/models.action';
import { GatewayControlComponent } from '../../../components/control/gateway/gateway.component';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { selectMessageGroupsState } from 'src/app/store/selectors/selectors';
import { SelectDateComponent } from 'src/app/cloud/devices/pages/data/metering-devices/select-date/select-date.component';
import {
    GetEventLogsModalComponent
} from 'src/app/cloud/devices/pages/data/metering-devices/data-show/components/get-event-logs-modal/get-event-logs-modal.component';
import {
    EventLogsModalComponent
} from 'src/app/cloud/devices/pages/data/gateways/event-logs-modal/event-logs-modal.component';
import {GatewaysDataComponent} from '@cloud/devices/pages/data/gateways/data/data.component';

// TODO: find common way and make compatible with metering devices data tab

@Component({
    selector: 'data-gateways',
    templateUrl: './data.component.html',
    styleUrls: ['./data.component.less']
})
export class DataGatewaysComponent implements OnInit, OnDestroy {
    subscribers = [];
    @ViewChild(SelectDateComponent) selectDateComponent: SelectDateComponent;
    @ViewChild(GatewaysDataComponent) gatewaysDataComponent: GatewaysDataComponent;
    public changeCurrentTabEvent = new EventEmitter();

    currentDevice;
    @Output() refreshData = new Subject<any>();
    messageGroups = [];
    dateForm: any = null;
    msgGroup = 1;
    profileType = 60;
    currentTab = 'tariffs';
    deviceId: number;
    controls = null;
    public selectedDate = null;
    mapTabs = {
        Показания: 'tariffs',
        'Профиль мощности': 'power_profiles',
        Тревоги: 'alarm',
        Журнал: 'journal',
        'Служебная информация': 'service_information',
        'Мгновенные показания': 'instant_readings'
    };
    model_class_id: number;
    public currentControl;
    journalDeviceAllSelect = true;
    typeJournal: any;
    public getJournalMessagesTypes: any[];
    currentLanguage;
    public msgTypes = [];
    public msgType = null;
    protected ngUnsubscribe: Subject<void> = new Subject<void>();
    private currentModel: any;
    private readonly destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private devicesService: DevicesService,
        private buttonsService: ButtonsService,
        private translate: TranslateService,
        public cloud: CloudComponent,
        private store: Store<{}>,
        public dialog: MatDialog,
        public dateManipulations: DateManipulations,
        public _checkAccess: CheckAccess
    ) {
        this.deviceId = <number>parseInt(this.route.snapshot.paramMap.get('id'), 0);
        this.buttonsService.setButtons([]);
        this.cloud.goToBack = true;

        CloudService.setLanguage(translate);
    }

    get refresh() {
        return this.devicesService.refreshData;
        // return this.refreshData;
    }

    set refresh(value: any) {
        this.devicesService.refreshData.next(value);
        // this.refreshData.next(value);
    }

    ngOnInit() {
        if (this.deviceId) {
            this.devicesService.getOneGateway(this.deviceId).subscribe((device: any) => {
                this.currentDevice = device;
                this.store
                    .pipe(select(fromModels.getGatewayModelById, { id: device.model_id }))
                    .pipe(takeUntil(this.destroyed$))
                    .subscribe((model) => {
                        this.currentModel = model;
                        this.currentDevice['model'] = model;
                        if (this.currentModel) {
                            this.currentControl = _.get(this.currentModel, 'options.control', null);
                            if (this.currentModel.id === 224 || this.currentModel.id === 223) {
                                this.currentControl = true;
                            }

                            this.msgTypes = this.currentDevice?.model?.tabs?.[this.currentTab]?.msgTypes ?? [];

                            if (this.msgTypes?.length) {
                                this.msgType = this.msgTypes[0];
                            } else if (typeof this.msgTypes === 'number') {
                                this.msgType = this.msgTypes;
                            } else {
                                this.msgType = 1;
                            }

                            // setTimeout(() => {
                                this.refresh = {
                                    msgType: this.msgType,
                                    tab: this.currentTab
                                };
                            // }, 100);
                            this.devicesService.getJournalMessagesTypes().subscribe((types) => {
                                this.getJournalMessagesTypes = types.filter((w) => this.msgTypes.includes(w.id));
                                this.typeJournal = this.getJournalMessagesTypes.map((j) => j.id);
                            });
                        }
                    });
            });
        }

        this.store
            .pipe(select(selectMessageGroupsState))
            .pipe(takeUntil(this.destroyed$))
            .subscribe((groups) => {
                this.messageGroups = groups;
            });

        this.currentLanguage = this.translate.currentLang;
    }

    public ngOnDestroy(): void {
        this.subscribers.forEach((item) => {
            item.unsubscribe();
        });
        this.destroyed$.next(null);
        this.destroyed$.complete();
        this.devicesService.refreshData.next(null);
    }

    goToControls() {
        this.dialog.open(GatewayControlComponent, {
            disableClose: false,
            width: '95%',
            data: {
                device: this.currentDevice,
                model: this.currentModel,
                control: this.currentControl
            }
        });
    }

    getEventLogs() {
        this.dialog.open(EventLogsModalComponent, {
            width: '450px',
            data: {
                device_id: this.currentDevice.id,
                model_id: this.currentDevice.model_id
            }
        });
    }

    dateChange(date) {
        this.dateForm = date;
        setTimeout(() => {
            this.refresh = { msgType: this.msgType, tab: this.currentTab };
        });
    }

    msgGroupChange(msgGroup) {
        this.msgGroup = msgGroup;
    }

    typeJournalChange(typeJournal) {
        this.typeJournal = typeJournal;
    }

    msgTypeChange(msgType) {
        this.msgType = msgType;
    }

    powerProfileTypeChange(profileType) {
        this.profileType = profileType;
    }

    changeTab(event) {
        const tab = event.tab._viewContainerRef.element.nativeElement.dataset.id;
        this.currentTab = tab;
        this.changeCurrentTabEvent.emit(tab);
        this.selectDateComponent.changePeriod(false);
        // this.disablePagination = true;
        if (this.deviceId !== null) {
            this.msgTypes = _.get(this.currentDevice, 'model.tabs.' + tab + '.msgTypes', []);
            if (this.msgTypes.length > 1 || this.msgTypes.length === 1) {
                this.msgType = this.msgTypes[0];
            } else if (typeof this.msgTypes === 'number') {
                this.msgType = this.msgTypes;
            } else {
                this.msgType = 1;
            }
            setTimeout(() => {
                this.refresh = {
                    msgType: this.msgType,
                    tab: tab
                };
                // this.disablePagination = false;
            }, 1000);
        }
    }
    downloadExcel() {
        this.gatewaysDataComponent.exportExcel();
    }
}
